{
	"general" : {
		"Add": "Aggiungi",
		"Cancel": "Annulla",
		"Edit": "Modifica",
		"Share": "Condividi",
		"View": "Visualizza",
		"Loading": "Caricamento...",
		"Select": "Seleziona...",
		"Menu": "Menù",
		"My_account": "Il mio account",
		"Admin": "Admin",

		"APP_Language": "Lingua APP",
		"International_sale_contract": "Contratto di vendita internazionale",
		"INTERNATION_SALE_CONTRACT_DESCRIPTION": "Contratto di vendita internazionale della CCI (Prodotti manifatturati destinati alla rivendita)",
		"PRODUCT_EXPORT_ALERT_MSG": "ATTENZIONE! Per esportare i prodotti indicati nel paese di destinazione è necessario disporre di autorizzazioni speciali. Assicurati di avere tutte le autorizzazioni prima di concludere il contratto.",
		"SHIPPING_MAX_DATE_ALERT_MSG": "ATTENZIONE! L'intervallo specificato per data di consegna non è valido.",
		"Save": "Salva",

		"Business_name": "Ragione sociale",
		"Vat_code": "Partita IVA",
		"Street_or_Square": "Via o Piazza",
		"Country": "Stato",
		"City": "Città",
		"Province": "Provincia",
		"Postal_code": "CAP",
		
		"Data": "Dati",
		"Seller": "Venditore",
		"Buyer": "Compratore",
		"Legal_representative": "Rappresentante legale",
		"Contact_person": "Persona di contatto",
		"Name": "Nome",
		"Surname": "Cognome",
		"Email": "Email",
		"Phone": "Telefono",
		"Signatory": "Firmatario",
		"SIGNATORY_TIP": "Specifica se il legale rappresentante è anche firmatario del contratto.",
		"Personal_responsibility": "Responsabilità personali",
		"PERSONAL_RESPONSIBILITY_TIP": "Specifica se il legale rappresentante risponde personalmente di questo contratto. In caso contrario, si assume che la responsabilità ricada sull'azienda.",

		"Login_to_your_account": "Accedi al tuo account",
		"Manage_your_contracts": "Gestisci i tuou contratti",
		"Password": "Password",
		"PLACEHOLDER_Email": "La tua email",
		"PLACEHOLDER_Password": "La tua password",

		"Terms": "Condizioni generali",
		"TERMS_DESCRIPTION": "Qui andranno specificate tutte le condizioni generali.",
			
		"Contract_saved_title": "Contratto salvato!",
		"Contract_saved_subtitle": "La transazione è stata memorizzata sulla Blockchain",

		"VALIDATION_REQUIRED_FIELD": "Campo obbligatorio.",
		"VALIDATION_PHONE_FIELD": "Campo obbligatorio. Deve contenere da 8 a 12 cifre."
	},
	"intro": {
		"WELCOME": "Benvenuto/a nella compilazione del contratto di vendita internazionale!",
		"SELLER": "Qui andranno inseriti i dati del venditore.",
		"BUYER": "Qui quelli del compratore!",
		"GENERAL_TERMS": "In questo blocco trovi le condizioni generali del contratto.",
		"SPECIAL_TERMS": "In tutti i blocchi successivi trovi invece le condizioni speciali del contratto."
	},
	"terms": {
        "TIP_MSG": "In questa sezione troverai i termini e le condizioni generali del contratto. Questi termini si applicano a tutti i contratti di vendita internazionale, a meno che non siano specificate condizioni speciali. Questo modello si basa sulla <a href='https://uncitral.un.org/sites/uncitral.un.org/files/media-documents/uncitral/en/19-09951_e_ebook.pdf' target='_blank'>Convenzione di Vienna (CISG)</a>, che stabilisce regole chiare sugli obblighi del venditore e dell'acquirente, sulla consegna, sul pagamento e su molti altri aspetti. Essa si applica automaticamente in molti paesi. (<a href='https://treaties.un.org/Pages/ViewDetails.aspx?src=TREATY&mtdsg_no=X-10&chapter=10&clang=_en' target='blank'>verifica se il tuo paese è tra quelli in cui si applica</a>), a meno che non venga espressamente esclusa.",
        "ART_1": {
            "TITLE": "Art. 1 - Generale",
            "ART_1.1": "1. Queste Condizioni Generali sono destinate ad essere applicate insieme alle Condizioni Specifiche (Parte A) del Modello ICC di Contratto di Vendita Internazionale (Beni Manifatturati), ma possono anche essere incorporate autonomamente in qualsiasi contratto di vendita. Qualora queste Condizioni Generali (Parte B) siano utilizzate indipendentemente dalle suddette Condizioni Specifiche (Parte A), qualsiasi riferimento nella Parte B alla Parte A sarà interpretato come un riferimento a qualsiasi specifica condizione concordata tra le parti. In caso di contraddizione tra queste Condizioni Generali e qualsiasi condizione specifica concordata tra le parti, prevarranno le condizioni specifiche.",
            "ART_1.2": "2. Qualsiasi questione relativa a questo contratto che non sia disciplinata dalle disposizioni contenute nel contratto stesso (cioè queste Condizioni Generali e qualsiasi condizione specifica concordata tra le parti) sarà regolata: a) dalla Convenzione delle Nazioni Unite sui Contratti di Vendita Internazionale di Beni (Convenzione di Vienna del 1980, di seguito denominata CISG); e b) nella misura in cui tali questioni non siano coperte dalla CISG e che non sia stata concordata alcuna legge applicabile, dalla legge del paese in cui il Venditore ha la propria sede d'affari .",
            "ART_1.3": "3. Qualsiasi riferimento a una pubblicazione della Camera di Commercio Internazionale si intende fatto alla versione in vigore alla data di conclusione del contratto.",
            "ART_1.4": "4. Nessuna modifica al contratto è valida a meno che non sia concordata o attestata per iscritto. Tuttavia, una parte può essere esclusa dall'invocare questa disposizione nella misura in cui l'altra parte abbia fatto affidamento su tale condotta.",
            "ART_1.5": "5. Qualsiasi limitazione ai rimedi in caso di violazione del contratto sarà inefficace in caso di frode o colpa grave della parte inadempiente ."
        },
        "ART_2": {
            "TITLE": "Art. 2 - Caratteristiche dei beni",
            "ART_2.1": "1. Si conviene che qualsiasi informazione relativa ai beni e al loro utilizzo, come pesi, dimensioni, capacità, prezzi, colori e altri dati contenuti in cataloghi, prospetti, circolari, pubblicità, illustrazioni, listini prezzi del Venditore, non avrà effetto come termini contrattuali a meno che non sia espressamente menzionata nel contratto.",
            "ART_2.2": "2. Salvo diverso accordo, l'Acquirente non acquisisce alcun diritto di proprietà su software, disegni, ecc. che possono essergli stati messi a disposizione. Il Venditore rimane inoltre l'unico proprietario di qualsiasi diritto di proprietà intellettuale o industriale relativo ai beni.",
            "ART_2.3": "3. Si conviene che i beni siano adatti allo scopo per cui sono destinati per loro stessa natura o come risulta evidente dal contratto di vendita.",
            "ART_2.4": "4. Se nel contratto di vendita viene fatto espresso riferimento a regolamenti tecnici, di sicurezza, di qualità o ad altri documenti chiaramente designati nel contratto, anche se non allegati al contratto, si ritiene che il Venditore ne abbia conoscenza. Il Venditore dovrà sostenere i costi relativi e ottenere tempestivamente le autorizzazioni, i permessi o le licenze necessarie per l'esecuzione dell'accordo e per conformarsi alle condizioni in esso stabilite."
        },
        "ART_3": {
            "TITLE": "Art. 3 - Ispezione dei beni prima della spedizione",
            "TEXT": "Se le parti hanno concordato che l'Acquirente ha il diritto di ispezionare i beni prima della spedizione, il Venditore deve notificare all'Acquirente, con un preavviso ragionevole prima della spedizione, che i beni sono pronti per l'ispezione nel luogo concordato."
        },
        "ART_4": {
            "TITLE": "Art. 4 - Prezzo",
            "ART_4.1": "1. Il prezzo indicato nella clausola A-2 (prezzo contrattuale) include tutti i costi a carico del Venditore secondo quanto previsto da questo contratto. Tuttavia, se il Venditore dovesse sostenere costi che, in base a questo contratto, sono a carico dell'Acquirente (ad esempio, per il trasporto o l'assicurazione secondo i termini FCA, EXW, FAS o FOB), tali importi non saranno considerati inclusi nel prezzo indicato nella clausola A-2 .",
            "ART_4.2": "2. Se non è stato concordato alcun prezzo, si applicherà il listino prezzi corrente del Venditore al momento della conclusione del contratto. In assenza di un listino prezzi aggiornato, si applicherà il prezzo generalmente praticato per tali beni al momento della conclusione del contratto nella valuta del Venditore.",
            "ART_4.3": "3. Salvo diverso accordo scritto, il prezzo non include tasse indirette (IVA, imposta sulle vendite, accise, …) e non è soggetto ad adeguamenti di prezzo."
        },
        "ART_5": {
            "TITLE": "Art. 5 - Condizioni di pagamento",
            "ART_5.1": "1. Salvo diverso accordo scritto o desunto da precedenti rapporti commerciali tra le parti, il pagamento del prezzo e di qualsiasi altra somma dovuta dall'Acquirente al Venditore avverrà su conto aperto, con scadenza a 30 giorni dalla data della fattura. Gli importi dovuti dovranno essere trasferiti, salvo diverso accordo, tramite bonifico telegrafico o rimessa alla banca del Venditore nel paese del Venditore, per conto del Venditore. L'Acquirente sarà considerato adempiente ai propri obblighi di pagamento nel momento in cui le somme dovute saranno state ricevute dalla banca del Venditore in fondi immediatamente disponibili.",
            "ART_5.2": "2. Se le parti hanno concordato un pagamento anticipato, senza ulteriori specificazioni, si presumerà che tale pagamento anticipato, salvo diverso accordo, si riferisca all'intero prezzo e che debba essere ricevuto dalla banca del Venditore in fondi immediatamente disponibili almeno 30 giorni prima della data di spedizione concordata o della prima data all'interno del periodo di spedizione concordato. Se il pagamento anticipato è stato concordato solo per una parte del prezzo contrattuale, le condizioni di pagamento dell'importo residuo saranno determinate secondo le regole stabilite in questo articolo.",
            "ART_5.3": "3. Se le parti hanno concordato il pagamento tramite credito documentario, salvo diverso accordo, l'Acquirente dovrà predisporre l'emissione di un credito documentario a favore del Venditore da parte di una banca affidabile, conforme alle Norme ed Usi Uniformi relativi ai Crediti Documentari (UCP 600) pubblicate dalla Camera di Commercio Internazionale, e dovrà essere notificato almeno 30 giorni prima della data di spedizione concordata o almeno 30 giorni prima della prima data all'interno del periodo di spedizione concordato. Salvo diverso accordo, il credito documentario dovrà essere pagabile a vista e consentire il trasbordo, ma non consegne parziali.",
            "ART_5.4": "4. Se le parti hanno concordato il pagamento tramite incasso documentario, salvo diverso accordo, i documenti saranno consegnati contro pagamento (D/P) e l'operazione sarà in ogni caso soggetta alle Norme Uniformi per gli Incassi (URC 522) pubblicate dalla Camera di Commercio Internazionale.",
            "ART_5.5": "5. Se le parti hanno concordato il pagamento garantito da un Obbligo di Pagamento Bancario (Bank Payment Obligation), salvo diverso accordo, l'Acquirente dovrà garantire che il Venditore riceva un'assicurazione di pagamento conforme ai termini di pagamento concordati, sotto forma di un Obbligo di Pagamento Bancario emesso da una banca a favore della banca del Venditore, soggetto alle Norme Uniformi per gli Obblighi di Pagamento Bancario (UR BPO) pubblicate dalla Camera di Commercio Internazionale, e notificato almeno 30 giorni prima della data di spedizione concordata o almeno 30 giorni prima della prima data all'interno del periodo di spedizione concordato. Salvo diverso accordo, l'Obbligo di Pagamento Bancario dovrà essere pagabile a vista e consentire il trasbordo, ma non consegne parziali.",
            "ART_5.6": "6. Nella misura in cui le parti abbiano concordato che il pagamento sia garantito da una garanzia bancaria, l'Acquirente dovrà fornire, almeno 30 giorni prima della data di spedizione concordata o almeno 30 giorni prima della prima data all'interno del periodo di spedizione concordato, una garanzia bancaria a prima richiesta conforme alle Norme Uniformi per le Garanzie a Prima Richiesta (URDG 758) pubblicate dalla Camera di Commercio Internazionale, o una lettera di credito standby conforme a tali Norme, alle International Standby Practices (ISP 98) o alle Norme ed Usi Uniformi relativi ai Crediti Documentari (UCP 600) pubblicate dalla Camera di Commercio Internazionale, emessa in ogni caso da una banca affidabile."
        },
        "ART_6": {
            "TITLE": "Art. 6 - Interessi in caso di ritardo nel pagamento",
            "ART_6.1": "1. Se una parte non paga una somma di denaro alla scadenza, l'altra parte ha diritto a interessi su tale somma dal momento in cui il pagamento è dovuto fino al momento in cui viene effettuato.",
            "ART_6.2": "2. Salvo diverso accordo, il tasso di interesse sarà pari al 5% in più rispetto al tasso medio di prestito a breve termine applicato dalle banche ai clienti primari per la valuta di pagamento nel luogo di pagamento o, se tale tasso non esiste in quel luogo, si applicherà lo stesso tasso nello Stato della valuta di pagamento."
        },
        "ART_7": {
            "TITLE": "Art. 7 - Riserva di proprietà",
            "TEXT": "Se le parti hanno validamente concordato una riserva di proprietà, i beni, nonostante la consegna e il passaggio del rischio sugli stessi, rimarranno di proprietà del Venditore fino al completo pagamento del prezzo, o come diversamente concordato."
        },
        "ART_8": {
            "TITLE": "Art. 8 - Termine contrattuale di consegna",
            "TEXT": "Salvo diverso accordo, la consegna avverrà in modalità FCA presso i locali del Venditore (Incoterms® 2020 Rules)."
        },
        "ART_9": {
            "TITLE": "Art. 9 - Documenti",
            "TEXT": "I documenti (se presenti) indicati nella regola Incoterms® applicabile o, se nessuna regola Incoterms® è applicabile, in conformità a eventuali precedenti prassi commerciali."
        },
        "ART_10": {
            "TITLE": "Art. 10 - Ritardo nella consegna, mancata consegna e rimedi applicabili",
            "ART_10.1": "1. Se le parti hanno concordato una data di annullamento nella clausola A-9, l'Acquirente può dichiarare risolto il contratto notificandolo al Venditore nel caso in cui la consegna non sia avvenuta entro tale data di annullamento, indipendentemente dalla causa (incluso un evento di forza maggiore).",
            "ART_10.2": "2. In caso di ritardo nella consegna di qualsiasi bene, l'Acquirente ha diritto a richiedere l'adempimento e una penale per il ritardo pari allo 0,5% (o altra percentuale concordata) del prezzo dei beni per ogni settimana di ritardo iniziata. Le penali per ritardo non potranno superare il 5% del prezzo dei beni consegnati in ritardo o l'importo massimo eventualmente concordato nella clausola A-10.",
            "ART_10.3": "3. Qualora l'articolo 10.1 non sia applicabile e il Venditore non abbia consegnato i beni entro la data in cui l'Acquirente è diventato titolare del diritto all'importo massimo della penale stabilita nell'articolo 10.2, l'Acquirente potrà in qualsiasi momento richiedere l'adempimento o dichiarare risolto il contratto per iscritto.",
            "ART_10.4": "4. In caso di risoluzione del contratto ai sensi dell'articolo 10.1 o 10.3, l'Acquirente ha diritto a richiedere il risarcimento danni, che nel complesso non potrà superare il prezzo dei beni non consegnati o l'importo massimo eventualmente concordato nella clausola A-11.",
            "ART_10.5": "5. I rimedi previsti in questo articolo escludono qualsiasi altro rimedio per ritardo nella consegna o mancata consegna ."
        },
        "ART_11": {
            "TITLE": "Art. 11 - Non conformità dei beni",
            "ART_11.1": "1. L'Acquirente deve esaminare i beni il prima possibile dopo il loro arrivo presso la sede dell'Acquirente o in qualsiasi altro luogo di esame concordato e deve notificare per iscritto al Venditore qualsiasi difetto di conformità, specificando la natura del difetto entro un termine ragionevole dalla data in cui l'Acquirente scopre o avrebbe dovuto scoprire il difetto. In ogni caso, l'Acquirente non avrà diritto a rimedi per la non conformità se non ne dà comunicazione al Venditore entro 24 mesi dalla data di arrivo dei beni presso la sede dell'Acquirente o in qualsiasi altro luogo di esame concordato, se esistente.",
            "ART_11.2": "2. I beni saranno considerati conformi al contratto nonostante lievi discrepanze che siano usuali nel settore specifico o derivanti da prassi commerciali tra le parti.",
            "ART_11.3": "3. Qualora i beni non siano conformi, il Venditore, a sua discrezione e a condizione che possa farlo senza ritardi irragionevoli e senza causare un disagio irragionevole all'Acquirente, potrà: a) sostituire i beni con beni conformi, senza alcun costo aggiuntivo per l'Acquirente; oppure b) riparare i beni, senza alcun costo aggiuntivo per l'Acquirente. L'Acquirente avrà diritto a una penale per il ritardo dovuto alla sostituzione o riparazione, come specificato nell'articolo 10.2 o come eventualmente concordato nella clausola A-10.",
            "ART_11.4": "4. Se il Venditore non ha adempiuto o ha rifiutato di adempiere correttamente ai propri obblighi ai sensi dell'articolo 11.3 entro un periodo di tempo ragionevole e se le parti non hanno concordato una riduzione del prezzo, l'Acquirente potrà avvalersi dei rimedi previsti dalla CISG, tenendo conto dei termini stabiliti nel presente contratto. Per quanto riguarda i danni provati dall'Acquirente, l'importo massimo è limitato al prezzo contrattualmente concordato per i beni non conformi.",
            "ART_11.5": "5. Salvo diverso accordo scritto, i rimedi previsti nel presente articolo 11 escludono qualsiasi altro rimedio per la non conformità.",
            "ART_11.6": "6. Salvo diverso accordo scritto, nessuna azione per difetto di conformità potrà essere intentata dall'Acquirente, sia davanti a tribunali ordinari che arbitrali, dopo 4 anni dalla data di arrivo dei beni presso il luogo di esame. È espressamente convenuto che, dopo la scadenza di tale termine, l'Acquirente non potrà eccepire la non conformità dei beni né avanzare una contropretesa in merito, come difesa in caso di azione intentata dal Venditore contro l'Acquirente per inadempimento del presente contratto."
        },
        "ART_12": {
            "TITLE": "Art. 12 - Cooperazione tra le parti",
            "ART_12.1": "1. L'Acquirente deve informare tempestivamente il Venditore di qualsiasi reclamo avanzato contro l'Acquirente dai propri clienti o da terzi in merito ai beni consegnati o ai diritti di proprietà industriale o intellettuale ad essi relativi .",
            "ART_12.2": "2. Il Venditore informerà tempestivamente l'Acquirente di qualsiasi reclamo che possa coinvolgere la responsabilità del prodotto a carico dell'Acquirente  ."
        },
        "ART_13": {
            "TITLE": "Art. 13 - Forza maggiore",
            "ART_13.1": "1.  Una parte non è responsabile per l'inadempimento di qualsiasi suo obbligo nella misura in cui dimostri: a) che l'inadempimento è dovuto a un impedimento al di fuori del suo controllo, b) che non ci si poteva ragionevolmente aspettare che prendesse in considerazione tale impedimento e i suoi effetti sulla sua capacità di adempiere al momento della conclusione del contratto, e c) che non poteva ragionevolmente evitare o superare l'impedimento o i suoi effetti.",
            "ART_13.2": "2. La parte che invoca l’esonero da responsabilità deve, non appena possibile dopo che l'impedimento e i suoi effetti sulla sua capacità di adempiere siano divenuti noti, notificare all'altra parte tale impedimento e i suoi effetti sulla capacità di adempiere. La notifica dovrà essere fornita anche quando cessano i motivi dell’esonero. La mancata comunicazione della notifica renderà la parte inadempiente responsabile per i danni derivanti da perdite che altrimenti avrebbero potuto essere evitate.",
            "ART_13.3": "3. Fatto salvo quanto previsto dall’articolo 10.2, un motivo di esonero ai sensi della presente clausola solleva la parte inadempiente dalla responsabilità per danni, da penalità e altre sanzioni contrattuali, nonché dall'obbligo di pagare interessi sulle somme dovute, per tutto il periodo e nella misura in cui tale motivo sussista.", 
            "ART_13.4": "4. Se i motivi dell’esonero persistono per più di tre (3) mesi, ciascuna delle parti avrà il diritto di dichiarare risolto il contratto senza necessità di preavviso."
        },
		"ART_14": {
			"TITLE": "Art. 14 - Sanzioni e restrizioni commerciali",
			"ART_14.1": "1. I beni, la tecnologia, i materiali e i servizi correlati, nonché le informazioni coperte dal presente accordo, possono essere soggetti a restrizioni commerciali, in particolare a controlli sulle esportazioni o sulle importazioni. L'Acquirente non deve consapevolmente vendere, esportare, importare, trasferire o cedere, direttamente o indirettamente, i beni, la tecnologia, i materiali e i servizi correlati, nonché le informazioni coperte dal presente accordo a paesi, destinazioni o utenti finali vietati in base a sanzioni nazionali o internazionali. Inoltre, l'Acquirente non deve eseguire alcun ordine effettuato da o per conto di individui o entità non autorizzate da tali sanzioni. Qualsiasi ordine proveniente da, o che comporti la consegna a, individui o entità vietate situate all'interno o sotto il controllo di un paese soggetto a restrizioni commerciali e leggi sul controllo delle esportazioni/importazioni, o a individui o entità a cui tale paese destina le proprie attività con qualsiasi mezzo, non può essere eseguito senza una preventiva conferma al Venditore che non vi sia alcun motivo ragionevole per sospettare una violazione delle sanzioni. L'Acquirente deve notificare tempestivamente al Venditore qualsiasi modifica alla conformità con le leggi e i regolamenti sulle sanzioni commerciali che possa influenzare la sua capacità di adempiere agli obblighi previsti dal presente Contratto.",
			"ART_14.2": "2.Ogni volta che i beni sono soggetti a regolamenti di controllo delle esportazioni, il Venditore deve richiedere e ottenere, prima della consegna, qualsiasi licenza o autorizzazione necessaria ai sensi della legge applicabile. L'Acquirente accetta che qualsiasi termine di consegna contenuto nell'offerta del Venditore sia subordinato all'ottenimento di tutte le licenze o autorizzazioni richieste per l'esportazione o la fornitura dei beni. Le Parti devono collaborare e assistersi reciprocamente nell'ottenimento di tali licenze e autorizzazioni.",
			"ART_14.3": "3.L'Acquirente dichiara e garantisce al Venditore che la destinazione finale dei beni sarà quella comunicata al Venditore nell'ordine di acquisto."
		},
		"ART_15": {
            "TITLE": "Art. 15 - Risoluzione delle controversie",
            "ART_15.1": "1. In caso di qualsiasi controversia derivante dal o in connessione con il presente contratto, le parti devono innanzitutto sottoporre la controversia a una procedura ai sensi del Regolamento di Mediazione ICC. L'avvio di un procedimento ai sensi del Regolamento di Mediazione ICC non impedisce a nessuna delle parti di avviare un arbitrato/una controversia legale conformemente alla sottoclausola 15.2 qui di seguito  .",
            "ART_15.2": "2. Tutte le controversie derivanti dal o in connessione con il presente contratto saranno definitivamente risolte secondo il Regolamento di Arbitrato della Camera di Commercio Internazionale da uno o più arbitri nominati conformemente a tale Regolamento."
		}
	},
	"A1": {
		"TITLE": "A-1 Prodotti venduti",
		"TIP_MSG": "Assicurati di specificare chiaramente tutti i dettagli delle merci, compresi codice prodotto, origine, descrizione e quantità. Un errore o una vaghezza qui può causare problemi doganali o contestazioni da parte dell'acquirente. Includi dettagli come la voce doganale (o HS code) per facilitare la conformità alle normative di esportazione e importazione.",
		"Unnamed_product": "Prodotto senza nome",
		"Code": "Codice",
		"Product_code": "Codice prodotto",
		"Description": "Descrizione",
		"Product_description": "Descrizione prodotto",
		"Quantity": "Quantità",
		"Price": "Prezzo",
		"Origin": "Origine",
		"Product_origin": "Origine del prodotto",
		"HS_Code": "HS Code",
		"Product_HS_Code": "HS Code del prodotto",
		"HS_CODE_TIP": "Il codice merceologico o codice doganale è il codice a 6 cifre del prodotto ai sensi del Harmonised System della World Customs Organisation (www.wcotradetools.org/en/harmonized-system) o qualsiasi codice più specifico (esteso) ai sensi della legislazione doganale nazionale. In queste ultime situazioni potrebbe essere opportuno indicare la natura del codice (TARIC, MERCOSUR, TVNED, ...)",
		"Add_product": "Aggiungi prodotto",
		"Remove_product": "Rimuovi prodotto"
	},
	"A2": {
		"TITLE": "A-2 Prezzo contrattuale",
		"TIP_MSG": "Specifica la valuta del contratto e l’importo. Ricorda che Il prezzo può essere soggetto a fluttuazioni valutarie ed eventuali oneri e costi aggiuntivi come tasse locali, IVA o dazi doganali.",
		"Amount": "Importo",
		"Total_amount": "Importo totale"
	},
	"A3": {
		"TITLE": "A-3 Termini di consegna",
		"ALERT_MSG": "Termini raccomandati: v. Introduzione, § 5)",
		"TIP_MSG": "Scegli attentamente i termini Incoterms® per definire chi è responsabile di trasporto e rischi durante la spedizione. Gli Incoterms® sono preferibili per il trasporto internazionale. Evita EXW e DDP a meno che non sia per transazioni all’interno della stessa area doganale.",
		"Terms_choose": "Scelta termini",
		"Shipping_carrier": "Vettore consegna",
		"Contact_person": "Persona di contatto",
		"Catalog": "Catalogo",
		"Select": "Seleziona...",
		"Term": "Termine",
		"Notes": "Note",
		"Place": "Luogo",
		"EXW": "EXW - Franco fabbrica",
		"FCA": "FCA - Franco vettore",
		"FAS": "FAS - Franco lungo bordo",
		"FOB": "FOB - Franco a bordo",
		"CFR": "CFR - Costo e nido",
		"CPT": "CPT - Costo e nolo",
		"CIF": "CIF - Costo, assicurazione e nolo",
		"CIP": "CIP - Trasporto e assicurazione pagati fino a",
		"CPT": "CPT - Trasporto pagato fino a",
		"DAF": "DAF - Reso frontiera",
		"DAP": "DAP - Reso nel luogo di destinazione convenuto",
		"DAT": "DAT - Reso al terminal",
		"DDP": "DDP - Reso sdoganato",
		"DDU": "DDU - Reso non sdoganato",
		"DEQ": "DEQ - Reso banchina",
		"DES": "DES - Reso ex ship",
		"DPU": "DPU - Consegnato in luogo scaricato"
	},
	"A4": {
		"TITLE": "A-4 Termine (data) di consegna (<a href='#' class='articleLink' data-toggle='modal' data-target='#articleModal' data-article='8'>art. 8</a>)",
		"ALERT_MSG": "Indicare qui la data o il periodo (ad es. settimana o mese) in cui o entro cui il Venditore è tenuto ad adempiere al suo obbligo di consegna conformemente alla clausola A4 del rispettivo Incoterm: v. Introduzione, § 6).",
		"TIP_MSG": "Inserisci una data precisa o un periodo per la consegna. Un’indicazione vaga può portare a ritardi e controversie. Definisci se il tempo di consegna è ‘entro’ o ‘non oltre’ una certa data. Assicurati che la data sia realistica considerando anche eventuali ostacoli come ritardi nelle operazioni doganali o le tempistiche necessarie per ottenere certificati, autorizzazioni o licenze.",
		"Shipping_date_min": "Data di consegna (min)",
		"Shipping_date_max": "Data di consegna (max)",
		"DATE_PLACEHOLDER": "gg/mm/AAAA"
	},
	"A5": {
		"TITLE": "A-5 Ispezione dei prodotti (<a href='#' class='articleLink' data-toggle='modal' data-target='#articleModal' data-article='3'>art. 3</a>)",
		"TIP_MSG": "In questa clausola opzionale, si può prevedere un’ispezione al momento della spedizione o prima della spedizione, in questo caso è consigliabile predisporre un mandato specifico dove si definiscano le regole dell’ispezione e gli obiettivi (per esempio: quantità dei beni, numero di lotti, etc.). Specificare il luogo e il momento dell’ispezione, oltre al responsabile del costo (venditore o acquirente), può prevenire controversie future. L'ispezione può avvenire prima o dopo la spedizione. È consigliabile effettuare l'ispezione nel Paese di origine per evitare costi di restituzione in caso di difetti. Da ricordare che l’ispezione è diversa dalla verifica/collaudo dei beni quando questi sono giunti nelle disponibilità del compratore.",
		"Inspection_type": "Tipo di ispezione",
		"Select": "Seleziona...",
		"Upon_shipment": "Upon shipment",
		"Before_shipment": "Prima della spedizione",
		"Other": "Altro",
		"Notes": "Note",
		"Inspection_surveyor": "Ispettore",
		"Not_required": "Non richiesto",
		"Required": "Richiesto",
		"Inspection_fee": "Fee di ispezione",
		"Paid_by_seller": "A carico del venditore",
		"Paid_by_buyer": "A carico del compratore"
	},
	"A6": {
		"TITLE": "A-6 Riserva di proprietà (<a href='#' class='articleLink' data-toggle='modal' data-target='#articleModal' data-article='7'>art. 7</a>)",
		"TIP_MSG": "Questa clausola permette al venditore di mantenere la proprietà delle merci fino al pagamento completo. Tuttavia, in alcuni Paesi, la riserva di proprietà non è riconosciuta legalmente. Assicurati che la clausola sia valida nella giurisdizione locale.",
		"Retention_of_title": "Riserva di proprietà",
		"None": "Nessuna",
		"Simple": "Semplice",
		"Extended": "Estesa"
	},
	"A7": {
		"TITLE": "A-7 Condizioni di pagamento (<a href='#' class='articleLink' data-toggle='modal' data-target='#articleModal' data-article='5'>art. 5</a>)",
		"TIP_MSG": "Definisci con precisione le condizioni di pagamento. È importante indicare il tipo di garanzia e tempistiche per minimizzare rischi di insolvenza.",
		"Payment_type": "Tipo di pagamento",
		"Delayed_payment": "Pagamento posticipato (art. 5.1)",
		"Prepayment": "Pagamento anticipato (art. 5.2)",
		"Payment_against_documents": "Pagamento contro documenti",
		"Irrevocable_documentary_credit": "Credito documentario irrevocabile (art. 5.3)",
		"Irrevocable bank payment obligation": "Obbligo irrevocabile di pagamento bancario (art. 5.5)",
		"Other": "Altro",
		"delayedPayment": {
			"Delayed_payment_days": "Data di pagamento (se diversa dall'art. 5.1)",
			"Days": "Giorni",
			"DELAYED_PAYMENT_DAYS_TIPS": "Giorni dalla data della fattura",
			"Other": "Altro",
			"DELAYED_PAYMENT_OTHER_TIPS": "Pagamento posticipato accompagnato da garanzia bancaria o standby letter of credit (art. 5.5)"
		},
		"prepayment": {
			"Prepayment_date": "Data di pagamento (se diversa dall'art. 5.2)",
			"DATE_PLACEHOLDER": "gg/mm/AAAA",
			"Prepayment_percentage": "Percentuale del prezzo totale",
			"PREPAYMENT_BOND_MSG": "Pagamento anticipato garantito da cauzione di pagamento anticipato",
			"Percentage": "Percentuale"
		},
		"paymentAgainstDocuments": {
			"Type": "Tipo",
			"Documents_against_payment": "D/P Documenti contro pagamento",
			"Documents_against_acceptation": "D/A Documenti contro accettazione"
		},
		"irrevocableDocumentaryCredit": {
			"Irrevocable_documentary_credit_issue_place": "Luogo di emissione (se applicabile)",
			"Irrevocable_documentary_credit_confirmation_place": "Luogo di conferma (se applicabile)",
			"Irrevocable_documentary_credit_use": "Credito utilizzabile per",
			"Irrevocable_bank_payment_obligation": "Obbligo irrevocabile di pagamento bancario (art. 5.5)",
			"Sight_payment": "Pagamento a vista",
			"Deferred_payment": "Pagamento differito",
			"Acceptance_of_drafts": "Accettazione di tratte",
			"Negotiation": "Negoziazione",
			"Partial_shippings": "Spedizioni parziali",
			"Transhipments": "Trasbordi",
			"Notification_date": "Data di notifica credito",
			"NOTIFICATION_DATE_TIPS": "Data entro cui il credito documentario dev'essere notificato al venditore (se diversa dall'art. 5.3)",
			"DATE_PLACEHOLDER": "gg/mm/AAAA",
			"Days": "Giorni",
			"Percentage": "Percentuale",
			"Place": "Luogo",
			"Other": "Altro"
		},
		"irrevocableBankPaymentObligation": {
			"Type": "Tipo",
			"Settlement_by_payment": "Liquidazione mediante pagamento",
			"Settlement_by_deferred_payment": "Liquidazione mediante pagamento differito",
			"Notification_date": "Data di notifica obbligo",
			"NOTIFICATION_DATE_TIPS": "Data entro cui l'obbligo irrevocabile di pagamento bancario dev'essere notificato al venditore (se diversa dall'art. 5.3)",
			"DATE_PLACEHOLDER": "gg/mm/AAAA",
			"Days": "Giorni",
			"Other": "Altro"
		},
		"other": {
			"PAYMENT_OTHER_TIPS": "Es: assegno, tratta bancaria, trasferimento elettronico di fondi sul conto della banca designata dal venditore"
		}
	},
	"A8": {
		"TITLE": "A-8 Documenti (<a href='#' class='articleLink' data-toggle='modal' data-target='#articleModal' data-article='9'>art. 9</a>)",
		"TIP_MSG": "Indica chiaramente i documenti richiesti per facilitare lo sdoganamento. Assicurati che i documenti siano in linea con i termini Incoterms® selezionati nella sezione A-3 delle presenti Condizioni Speciali. Verifica i requisiti formali che i documenti devono avere nel paese di destino dei beni in collaborazione con l’acquirente.",
		"Invoice": "Fattura commerciale",
		"DDT": "Documento di trasporto",
		"Insurance_document": "Documento di assicurazione",
		"Origin_certificate": "Certificato di origine",
		"Preferential_origin_certificate": "Certificato di origine preferenziale",
		"Inspection_certificate": "Certificato di ispezione",
		"Packing_list": "Lista imballaggio",
		"Other": "Altro"
	},
	"A9": {
		"TITLE": "A-9 Data di risoluzione (<a href='#' class='articleLink' data-toggle='modal' data-target='#articleModal' data-article='10'>art. 10</a>)",
		"ALERT_MSG": "DA COMPLETARE SOLO SE LE PARTI DESIDERANO MODIFICARE L'ARTICOLO. 10",
		"TIP_MSG": "Definire una data di cancellazione offre protezione all'acquirente in caso di ritardo eccessivo, ovvero in caso di forza maggiore. Se le merci non vengono consegnate entro la data stabilita, l'acquirente può dichiarare nullo il contratto.",
		"Termination_date": "Data di risoluzione",
		"DATE_PLACEHOLDER": "gg/mm/AAAA"
	},
	"A10": {
		"TITLE": "A-10 Responsabilità per ritardata consegna (<a href='#' class='articleLink' data-toggle='modal' data-target='#articleModal' data-article='10' data-subarticle='2'>art. 10.2</a>)",
		"ALERT_MSG": "DA COMPLETARE SOLO SE LE PARTI DESIDERANO MODIFICARE L'ARTICOLO 10.2",
		"TIP_MSG": "Includi eventuali danni liquidati per ritardi, specificando la percentuale e la durata massima del ritardo accettabile. Definire penali per ritardi può disincentivare il venditore dal causare ritardi. Es. 0,5% a settimana fino a un massimo del 5% del prezzo dei beni in ritardo.",
		"LATE_DELIVERY_PENALTY_CTA": "La penale per ritardata consegna sarà della percentuale di seguito specificata (del prezzo dei prodotti consegnati in ritardo) per ogni settimana di ritardo, entro il limite massimo in percentuale di seguito specificato (del prezzo dei prodotti di cui sopra).",
		"Price_percentage": "Percentuale del prezzo",
		"Max_limit_percentage": "Percentuale del limite massimo"
	},
	"A11": {
		"TITLE": "A-11 Limitazione di responsabilità per ritardata consegna (<a href='#' class='articleLink' data-toggle='modal' data-target='#articleModal' data-article='10' data-subarticle='4'>art. 10.4</a>)",
		"ALERT_MSG": "DA COMPLETARE SOLO SE LE PARTI DESIDERANO MODIFICARE L'ARTICOLO 10.4",
		"TIP_MSG": "Includi eventuali limitazione di responsabilità del venditore in caso di risoluzione per ritardata consegna.",
		"EXCEED_AMOUNT_CTA": "In caso di risoluzione per ritardata consegna, la responsabilità del Venditore per danni da ritardata consegna è limitata alla percentuale di seguito specificata del prezzo dei prodotti non consegnati.",
		"Seller_percentage": "Percentuale del venditore"
	},
	"A12": {
		"TITLE": "A-12 Luogo d'esame all'arrivo (<a href='#' class='articleLink' data-toggle='modal' data-target='#articleModal' data-article='11' data-subarticle='1'>art. 11.1</a>)",
		"ALERT_MSG": "DA COMPILARE SOLO SE LE PARTI DESIDERAVANO MODIFICARE L'ARTICOLO 11.1",
		"TIP_MSG": "Includere eventuali indicazioni sul luogo d'esame all'arrivo.",
		"NO_EXAMINATION_CTA": "La merce consegnata non dovrà essere esaminata",
		"BUSINESS_PLACE_OF_EXAMINATION_CTA": "La merce consegnata dovrà essere esaminata dopo il suo arrivo presso la sede del destinatario a cui la merce è inviata o reindirizzata.",
		"OTHER_PLACE_OF_EXAMINATION_CTA": "La merce consegnata dovrà essere esaminata dopo il suo arrivo presso il seguente luogo:"
	},
	"A13": {
		"TITLE": "A-13 Garanzia ai sensi delle normative dell'Unione Europea",
		"ALERT_MSG": "DA COMPILARE SOLO SE È APPLICABILE LE LEGGI DELLA UE",
		"TIP_MSG": "Includi eventuali indicazioni sulla garanzia.",
		"NO_WARRANTY_CTA": "Questo contratto non include un periodo di garanzia",
		"WARRANTY_AFTER_SIGNATURE_CTA": "Questo contratto include un periodo di garanzia di N mesi dopo la firma del contratto",
		"WARRANTY_AFTER_DELIVERY_CTA": "Questo contratto include un periodo di garanzia di N mesi dopo la consegna",
		"Months": "Mesi",
		"Software_updates": "Aggiornamenti software",
		"SW_UPDATES_NOT_APPLICABLE_CTA": "Non applicabili",
		"SW_UPDATES_FREE_OF_CHARGE_CTA": "Inclusi durante il periodo di garanzia - Gratuiti",
		"SW_UPDATES_AGAINS_PAYMENTS_CTA": "Inclusi durante il periodo di garanzia - A pagamento",
		"SW_UPDATES_TIPS": "Da specificare se il software è incluso"
	},
	"A14": {
		"TITLE": "A-14 Termine massimo per la notifica di non conformità (<a href='#' class='articleLink' data-toggle='modal' data-target='#articleModal' data-article='11' data-subarticle='2'>art. 11.2</a>)",
		"ALERT_MSG": "DA COMPILARE SOLO SE LE PARTI DESIDERAVANO MODIFICARE L'ARTICOLO 11.2",
		"TIP_MSG": "I difetti devono essere notificati al venditore immediatamente dopo la scoperta o non appena avrebbero dovuto essere scoperti, ma non oltre N mesi dall'arrivo della merce. Ciò non incide sui termini di prescrizione (art. 11.6).",
		"Months": "Mesi",
		"Months_after_delivery": "Mesi dopo l'arrivo della merce"
	},
	"A15": {
		"TITLE": "A-15 Limitazione di responsabilità per non conformità (<a href='#' class='articleLink' data-toggle='modal' data-target='#articleModal' data-article='11' data-subarticle='5'>art. 11.5</a>)",
		"ALERT_MSG": "DA COMPILARE SOLO SE LE PARTI DESIDERAVANO MODIFICARE L'ARTICOLO 11.5 ULTIMA FRASE",
		"TIP_MSG": "Definisci un limite massimo per la responsabilità del venditore per danni derivanti dalla mancanza di conformità della merce.",
		"LIMITATION_OF_LIABILITY_PERCENTAGE_CTA": "La responsabilità del venditore per danni derivanti dalla mancanza di conformità della merce è limitata alla perdita dimostrata (inclusa la perdita consequenziale, la perdita di profitto, ecc.) non superiore alla percentuale (specificata) del prezzo del contratto.",
		"Percentage": "Percentage",
		"LIMITATION_OF_LIABILITY_AMOUNT_CTA": "La responsabilità del venditore per danni derivanti dalla mancanza di conformità della merce è limitata alla perdita dimostrata (inclusa la perdita consequenziale, la perdita di profitto, ecc.) non superiore all'importo (specificato).",
		"Amount": "Importo"
	},
	"A16": {
		"TITLE": "A-16 Legge applicabile (<a href='#' class='articleLink' data-toggle='modal' data-target='#articleModal' data-article='1' data-subarticle='2'>art. 1.2</a>)",
		"ALERT_MSG": "Da completare solo se le parti desiderano sottoporre il contratto di vendita ad una legge nazionale invece che alla CVIM. La soluzione indicata qui sotto non è raccomandabile (v. Introduzione, § 3).",
		"TIP_MSG": "La scelta della legge applicabile può influenzare i rimedi in caso di controversia; verifica se applicare la legge del Paese del venditore o dell'acquirente. Se non diversamente specificato, si applicherà la legge del Paese del venditore. La Convenzione di Vienna (CISG) è spesso usata per uniformare i contratti di vendita internazionali.",
		"LAW_MSG": "Il presente contratto di vendita è sottoposto alla legge interna seguente.",
		"LAW2_MSG": "Qualsiasi questione non coperta dalla CVIM sarà regolata dalla legge seguente.",
		"Law": "Legge"
	},
	"A17": {
		"TITLE": "A-17 Risoluzione delle controversie (<a href='#' class='articleLink' data-toggle='modal' data-target='#articleModal' data-article='15'>art. 15</a>)",
		"ALERT_MSG": "Le due soluzioni indicate sotto (arbitrato o tribunali ordinari) sono alternative: le parti non possono sceglierle ambedue. In assenza di scelta si applicherà l'arbitrato della CCI, conformemente all'<a href='#' class='articleLink' data-toggle='modal' data-target='#articleModal' data-article='art. 14'>art. 14</a>.",
		"TIP_MSG": "Seleziona tra arbitrato o tribunale ordinario per risolvere le dispute; l’arbitrato può essere più rapido e meno costoso a lungo termine. L’arbitrato, regolato dalla Corte Internazionale dell’ICC, può essere più efficace per controversie internazionali. Specifica la sede e la lingua dell’arbitrato per evitare ambiguità.",
		"Dispute_resolution": "Risoluzione delle controversie",
		"OPTION_0": "ADR - Risoluzione alternativa delle controversie",
		"OPTION_1": "ARBITRATO",
		"OPTION_2": "CCI (conformemente all'art. 15)",
		"OPTION_3": "Altro (specificare)",
		"OPTION_4": "Giurisdizione ordinaria (specificare)",
		"Other": "Altro",
		"Competent_court": "Corte competente"
	},
	"A18": {
		"TITLE": "A-18 Altro",
		"TIP_MSG": "Utilizza questo spazio per inserire qualsiasi altra informazione o clausola che non rientra nelle sezioni precedenti. Assicurati che tutte le clausole aggiuntive siano chiare e non contraddicano le altre condizioni del contratto.",
		"Other": "Altro"
	},
	"accountPage": {
		"My_account": "Il mio account",
		"Anonymous_identity": "Identità anonima",
		"My_contracts": "I miei contratti",
		"No_contracts_to_show": "Nessun contratto da mostrare",
		"Add": "Aggiungi",
		"Contract_model": "Modello di contratto",
		"My_role": "Il mio ruolo",
		"Seller": "Venditore",
		"Buyer": "Compratore",
		"Contract_name": "Nome del contratto",
		"Create_contract": "Crea contratto"
	},
	"editAccountPage": {
		"Edit_account": "Modifica account",
		"Contact_data": "Contact data",
		"Name": "Nome",
		"Surname": "Cognome",
		"Email": "Email",
		"Phone": "Telefono",
		"Birthdate": "Data di nascita",
		"Main_address": "Indirizzo principale",
		"Street": "Via o Piazza",
		"City": "Città",
		"Province": "Provincia",
		"Region": "Regione",
		"Country": "Nazione",
		"Postal_code": "CAP",
		"PLACEHOLDER_name": "Il tuo nome",
		"PLACEHOLDER_surname": "Il tuo cognome",
		"PLACEHOLDER_email": "La tua email",
		"PLACEHOLDER_phone": "Il tuo telefono",
		"PLACEHOLDER_birthdate": "dd/mm/YYYY",
		"PLACEHOLDER_street": "Via o Piazza e numero civico",
		"PLACEHOLDER_city": "Città",
		"PLACEHOLDER_province": "Provincia",
		"PLACEHOLDER_region": "Regione",
		"PLACEHOLDER_country": "Nazione",
		"PLACEHOLDER_postalCode": "Codice postale"
	},
	"viewContractPage": {
		"View_contract": "Visualizza contratto",
		"Contract_draft": "Contratto in bozza",
		"CONTRACT_DRAFT_MSG": "Questo contratto è una bozza. Salvalo sulla blockchain per storicizzarlo in maniera persistente e sicura tramite sistema blockchain.",
		"Store_contract": "Salva contratto",
		"Contract_saved": "Contratto salvato",
		"CONTRACT_SAVED_MSG": "Questo contratto è stato storicizzato in maniera persistente e sicura tramite sistema blockchain ed è pronto per essere firmato.",
		"Sign_contract": "Firma contratto",
		"Contract_signed": "Contratto firmato",
		"Pay_contract": "Paga contratto",
		"CONTRACT_SIGNED_MSG": "Questo contratto è stato storicizzato e firmato in maniera persistente e sicura tramite sistema blockchain.",
		"Contract_paid": "Contratto pagato",
		"CONTRACT_PAID_MSG": "Questo contratto è stato pagato dal compratore. La merce è pronta per essere spedita.",
		"Courier": "Corriere",
		"Tracking_number": "Tracking number",
		"Ship_goods": "Spedisci merce",
		"Contract_shipped": "Merce spedita",
		"CONTRACT_SHIPPED_MSG": "La merce è stata spedita dal venditore ed è in attesa di consegna.",
		"Contract_delivered": "Merce consegnata",
		"CONTRACT_DELIVERED_MSG": "La merce è stata consegnata. L'importo è pronto per essere prelevato.",
		"Get_money": "Preleva importo",
		"Contract_status": "Stato del contratto",
		"Shipping_status": "Stato della spedizione",
		"Payment_status": "Stato del pagamento",
		"Payments": "Pagamenti",
		"Withdrawals": "Prelievi",
		"Transaction_id": "ID transazione",
		"Transaction_amount": "Importo transazione",
		"Transaction_fee": "Fee transazione",
		"Contract_details": "Dettagli del contratto",
		"Contract_id": "ID del contratto",
		"Contract_name": "Nome del contratto",
		"Contract_model": "Modello di contratto",
		"Contract_value": "Valore del contratto",
		"Seller": "Venditore",
		"Buyer": "Compratore",
		"Seller_signature": "Firma del venditore",
		"Buyer_signature": "Firma del compratore",
		"Contract_id_on_blockchain": "ID del contratto sulla blockchain",
		"Verify": "Verifica",
		"Change_log": "Registro delle modifiche",
		"Open_PDF": "Apri PDF"
	},
	"viewContractChangesPage": {
		"Change_log": "Registro delle modifiche",
		"Last_changes" : "Ultime modifiche",
		"LAST_CHANGES_DESCRIPTION": "Qui trovi tutte le modifiche effettuate al contratto"
	},
	"shareContractPage": {
		"Share_your_contract": "Condividi il tuo contratto",
		"SHARE_MSG": "Scrivi un messaggio e condividi il tuo contratto",
		"SHARE_TEXTAREA_MSG": "Ciao! Ecco il contratto che ho inizializzato, clicca per aggiungerlo al tuo account TradeOnChain!",
		"SHARE_CTA": "Condividi",
		"URL_MSG": "Link di condivisione del contratto",
		"URL_CTA": "Copia link",
		"QRCODE_MSG": "QR Code di condivisione del contratto"
	},
	"addContractPage": {
		"Add_contract": "Aggiungi contratto",
		"ADD_CONTRACT_MSG": "Clicca sul pulsante 'Aggiungi' per aggiungere questo contratto al tuo account e collaborare alla stesura."
	},
	"editContractPage": {
		"Edit_contract": "Modifica contratto"
	},
	"contractStatus": {
		"draft": "Bozza",
		"stored": "Salvato",
		"signed": "Firmato",
		"paid": "Pagato",
		"shipped": "Spedito",
		"delivered": "Consegnato",
		"completed": "Completato"
	},
	"paymentStatus": {
		"PROCESSING": "Processando",
		"AWAITING_PAYMENT_FROM_USER": "In attesa di pagamento",
		"PAYMENT_DONE_MARKED_BY_USER": "Pagamento effettuato",
		"PENDING_DELIVERY_FROM_TRANSAK": "In attesa di consegna",
		"ON_HOLD_PENDING_DELIVERY_FROM_TRANSAK": "In attesa di consegna",
		"COMPLETED": "Completato",
		"EXPIRED": "Scaduto",
		"FAILED": "Fallito",
		"CANCELLED": "Annullato",
		"REFUNDED": "Rimborsato"
	},
	"languages": {
		"Italian": "Italiano",
		"English": "Inglese"
	},
	"countries": {
		"af": "Afghanistan",
        "al": "Albania",
        "dz": "Algeria",
        "ad": "Andorra",
        "ao": "Angola",
        "ar": "Argentina",
        "am": "Armenia",
        "au": "Australia",
        "at": "Austria",
        "az": "Azerbaigian",
        "bh": "Bahrein",
        "bd": "Bangladesh",
        "by": "Bielorussia",
        "be": "Belgio",
        "bz": "Belize",
        "bj": "Benin",
        "bt": "Bhutan",
        "bo": "Bolivia",
        "ba": "Bosnia-Erzegovina",
        "bw": "Botswana",
        "br": "Brasile",
        "bn": "Brunei",
        "bg": "Bulgaria",
        "bf": "Burkina Faso",
        "bi": "Burundi",
        "kh": "Cambogia",
        "cm": "Camerun",
        "ca": "Canada",
        "cv": "Capo Verde",
        "td": "Ciad",
        "cl": "Cile",
        "cn": "Cina",
        "co": "Colombia",
        "cg": "Congo",
        "cr": "Costa Rica",
        "hr": "Croazia",
        "cu": "Cuba",
        "cy": "Cipro",
        "cz": "Repubblica Ceca",
        "dk": "Danimarca",
        "dj": "Gibuti",
        "do": "Repubblica Dominicana",
        "ec": "Ecuador",
        "eg": "Egitto",
        "sv": "El Salvador",
        "ee": "Estonia",
        "et": "Etiopia",
        "fi": "Finlandia",
        "fr": "Francia",
        "ga": "Gabon",
        "gm": "Gambia",
        "ge": "Georgia",
        "de": "Germania",
        "gh": "Ghana",
        "gr": "Grecia",
        "gt": "Guatemala",
        "gn": "Guinea",
        "ht": "Haiti",
        "hn": "Honduras",
        "hu": "Ungheria",
        "is": "Islanda",
        "in": "India",
        "id": "Indonesia",
        "ir": "Iran",
        "iq": "Iraq",
        "ie": "Irlanda",
        "il": "Israele",
        "it": "Italia",
        "jm": "Giamaica",
        "jp": "Giappone",
        "jo": "Giordania",
        "kz": "Kazakistan",
        "ke": "Kenya",
        "kr": "Corea del Sud",
        "kw": "Kuwait",
        "lv": "Lettonia",
        "lb": "Libano",
        "lr": "Liberia",
        "ly": "Libia",
        "lt": "Lituania",
        "lu": "Lussemburgo",
        "mg": "Madagascar",
        "my": "Malesia",
        "ml": "Mali",
        "mt": "Malta",
        "mx": "Messico",
        "mn": "Mongolia",
        "me": "Montenegro",
        "ma": "Marocco",
        "mz": "Mozambico",
        "mm": "Myanmar",
        "np": "Nepal",
        "nl": "Paesi Bassi",
        "nz": "Nuova Zelanda",
        "ng": "Nigeria",
        "no": "Norvegia",
        "om": "Oman",
        "pk": "Pakistan",
        "pa": "Panama",
        "py": "Paraguay",
        "pe": "Perù",
        "ph": "Filippine",
        "pl": "Polonia",
        "pt": "Portogallo",
        "qa": "Qatar",
        "ro": "Romania",
        "ru": "Russia",
        "rw": "Ruanda",
        "sa": "Arabia Saudita",
        "sn": "Senegal",
        "rs": "Serbia",
        "sg": "Singapore",
        "sk": "Slovacchia",
        "si": "Slovenia",
        "za": "Sudafrica",
        "es": "Spagna",
        "lk": "Sri Lanka",
        "se": "Svezia",
        "ch": "Svizzera",
        "sy": "Siria",
        "tw": "Taiwan",
        "th": "Thailandia",
        "tn": "Tunisia",
        "tr": "Turchia",
        "ua": "Ucraina",
        "ae": "Emirati Arabi Uniti",
        "gb": "Regno Unito",
        "us": "Stati Uniti",
        "uy": "Uruguay",
        "ve": "Venezuela",
        "vn": "Vietnam",
        "zm": "Zambia",
        "zw": "Zimbabwe"
	}
}