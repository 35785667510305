{
	"general" : {
		"Add": "Aggiungi",
		"Cancel": "Annulla",
		"Edit": "Modifica",
		"Share": "Condividi",
		"View": "Visualizza",
		"Loading": "Caricamento...",
		"Select": "Seleziona...",

		"APP_Language": "Lingua APP",
		"International_sale_contract": "Contratto di vendita internazionale",
		"INTERNATION_SALE_CONTRACT_DESCRIPTION": "Contratto di vendita internazionale della CCI (Prodotti manifatturati destinati alla rivendita)",
		"PRODUCT_EXPORT_ALERT_MSG": "ATTENZIONE! Per esportare i prodotti indicati nel paese di destinazione è necessario disporre di autorizzazioni speciali. Assicurati di avere tutte le autorizzazioni prima di concludere il contratto.",
		"Save": "Salva",

		"Business_name": "Ragione sociale",
		"Vat_code": "Partita IVA",
		"Street_or_Square": "Via o Piazza",
		"Country": "Stato",
		"City": "Città",
		"Province": "Provincia",
		"Postal_code": "CAP",
		
		"Data": "Dati",
		"Seller": "Venditore",
		"Buyer": "Compratore",
		"Legal_representative": "Rappresentante legale",
		"Contact_person": "Persona di contatto",
		"Name": "Nome",
		"Surname": "Cognome",
		"Email": "Email",
		"Phone": "Telefono",
		"Signatory": "Firmatario",
		"SIGNATORY_TIP": "Specifica se il legale rappresentante è anche firmatario del contratto.",
		"Personal_responsibility": "Responsabilità personali",
		"PERSONAL_RESPONSIBILITY_TIP": "Specifica se il legale rappresentante risponde personalmente di questo contratto. In caso contrario, si assume che la responsabilità ricada sull'azienda.",

		"Login_to_your_account": "Accedi al tuo account",
		"Manage_your_contracts": "Gestisci i tuou contratti",
		"Password": "Password",
		"PLACEHOLDER_Email": "La tua email",
		"PLACEHOLDER_Password": "La tua password",

		"Terms": "Condizioni generali",
		"TERMS_DESCRIPTION": "Qui andranno specificate tutte le condizioni generali.",
			
		"Contract_saved_title": "Contratto salvato!",
		"Contract_saved_subtitle": "La transazione è stata memorizzata sulla Blockchain",

		"VALIDATION_REQUIRED_FIELD": "Campo obbligatorio.",
		"VALIDATION_PHONE_FIELD": "Campo obbligatorio. Deve contenere da 8 a 12 cifre."
	},
	"intro": {
		"WELCOME": "Benvenuto/a nella compilazione del contratto di vendita internazionale!",
		"SELLER": "Qui andranno inseriti i dati del venditore.",
		"BUYER": "Qui quelli del compratore!",
		"GENERAL_TERMS": "In questo blocco trovi le condizioni generali del contratto.",
		"SPECIAL_TERMS": "In tutti i blocchi successivi trovi invece le condizioni speciali del contratto."
	},
	"terms": {
		"TIP_MSG": "In questa sezione trovi le condizioni generali del contratto. Queste condizioni si applicano a tutti i contratti di vendita internazionale, a meno che non vengano specificate condizioni speciali. Questo modello si basa sulla <a href='https://uncitral.un.org/sites/uncitral.un.org/files/media-documents/uncitral/en/19-09951_e_ebook.pdf' target='_blank'>Convenzione di Vienna (CISG)</a>, la quale stabilisce regole chiare su obblighi di venditore e acquirente, consegna, pagamento e molto altro. È applicata automaticamente in molti paesi (<a href='https://treaties.un.org/Pages/ViewDetails.aspx?src=TREATY&mtdsg_no=X-10&chapter=10&clang=_en' target='blank'>verifica se il tuo paese rientra tra quelli dove si applica</a>), a meno che non sia espressamente esclusa.",
		"ART_1": {
			"TITLE": "Art. 1 - Generale",
			"ART_1.1": "1. Le presenti Condizioni Generali sono destinate ad essere applicate insieme alle Condizioni Specifiche (Parte A) del Contratto Tipo di Vendita Internazionale ICC (Beni Manifatturati), ma possono anche essere incorporate autonomamente in qualsiasi contratto di vendita. Quando queste Condizioni Generali (Parte B) vengono utilizzate indipendentemente dalle suddette Condizioni Specifiche (Parte A), ogni riferimento nella Parte B alla Parte A sarà interpretato come un riferimento a qualsiasi condizione specifica pertinente concordata dalle parti. In caso di contraddizione tra queste Condizioni Generali e qualsiasi condizione specifica concordata tra le parti, prevalgono le condizioni specifiche.",
			"ART_1.2": "2. Qualsiasi questione relativa al presente contratto che non sia risolta dalle disposizioni contenute nel contratto stesso (cioè le presenti Condizioni Generali e qualsiasi condizione specifica concordata dalle parti) sarà regolata: A. dalla Convenzione delle Nazioni Unite sui contratti di vendita internazionale di merci (Convenzione di Vienna del 1980, di seguito denominata CISG), e B. nella misura in cui tali questioni non siano coperte dalla CISG e non sia stata concordata alcuna legge applicabile, con riferimento alla legge del paese in cui il Venditore ha la sua sede di attività.",
			"ART_1.3": "3. Qualsiasi riferimento a una pubblicazione della Camera di Commercio Internazionale si intende riferito alla versione in vigore al momento della conclusione del contratto.",
			"ART_1.4": "4. Nessuna modifica del contratto è valida, salvo che concordata o documentata per iscritto. Tuttavia, una parte può essere preclusa dall'affermare questa disposizione nella misura in cui l'altra parte abbia fatto affidamento su tale comportamento.",
			"ART_1.5": "5. Qualsiasi limitazione dei rimedi in caso di violazione del contratto sarà inefficace in caso di frode o negligenza grave da parte della parte inadempiente."
		},
		"ART_2": {
			"TITLE": "Art. 2 - Caratteristiche dei beni",
			"ART_2.1": "1. È concordato che qualsiasi informazione relativa ai beni e al loro uso, come pesi, dimensioni, capacità, prezzi, colori e altri dati contenuti in cataloghi, opuscoli, circolari, pubblicità, illustrazioni, listini dei prezzi del Venditore, non avrà valore come termine del contratto, salvo espressa indicazione nel contratto.",
			"ART_2.2": "2. Salvo diverso accordo, l'Acquirente non acquisisce diritti di proprietà su software, disegni, ecc. che potrebbero essere stati messi a sua disposizione. Il Venditore rimane inoltre il proprietario esclusivo di qualsiasi diritto di proprietà intellettuale o industriale relativo ai beni.",
			"ART_2.3": "3. È concordato che i beni sono adatti per lo scopo a cui sono destinati dalla loro natura o che risulta evidente dal contratto di vendita.",
			"ART_2.4": "4. Se nel contratto di vendita si fa riferimento esplicito a regolamenti tecnici, di sicurezza, di qualità o ad altri regolamenti e documenti chiaramente indicati nel contratto, anche se non allegati al contratto, si considera che il Venditore ne sia a conoscenza. Il Venditore si farà carico dei costi correlati e otterrà i necessari permessi, autorizzazioni o licenze richieste per l'esecuzione del contratto e per il rispetto delle condizioni in esso stipulate."
		},
		"ART_3": {
			"TITLE": "Art. 3 - Ispezione dei beni prima della spedizione",
			"TEXT": "Se le parti hanno concordato che l'Acquirente ha il diritto di ispezionare i beni prima della spedizione, il Venditore deve notificare all'Acquirente con un preavviso ragionevole che i beni sono pronti per l'ispezione nel luogo concordato."
		},
		"ART_4": {
			"TITLE": "Art. 4 - Prezzo",
			"ART_4.1": "1. Il prezzo indicato sotto A-2 (prezzo contrattuale) include qualsiasi costo che è a carico del Venditore secondo questo contratto. Tuttavia, se il Venditore si fa carico di costi che, secondo questo contratto, sono a carico dell'Acquirente (ad esempio, per trasporto o assicurazione secondo FCA, EXW, FAS o FOB), tali somme non saranno considerate incluse nel prezzo sotto A-2.",
			"ART_4.2": "2. Se non è stato concordato un prezzo, si applicherà il prezzo attuale di listino del Venditore al momento della conclusione del contratto. In assenza di tale prezzo di listino attuale, si applicherà il prezzo generalmente richiesto per tali beni al momento della conclusione del contratto nella valuta del Venditore.",
			"ART_4.3": "3. Salvo diverso accordo scritto, il prezzo non include le imposte indirette (IVA, imposta sulle vendite, accise, ...) e non è soggetto ad adeguamento del prezzo."
		},
		"ART_5": {
			"TITLE": "Art. 5 - Condizioni di pagamento",
			"ART_5.1": "1. Salvo diverso accordo scritto, o implicato da un corso precedente di trattative tra le parti, il pagamento del prezzo e di qualsiasi altra somma dovuta dall'Acquirente al Venditore sarà su conto aperto e il termine di pagamento sarà di 30 giorni dalla data della fattura. Gli importi dovuti dovranno essere trasferiti, salvo diverso accordo, tramite bonifico telegrafico o rimessa alla banca del Venditore nel paese del Venditore, per conto del Venditore, e l'Acquirente sarà considerato aver adempiuto ai propri obblighi di pagamento quando le somme rispettive saranno state ricevute dalla banca del Venditore in fondi immediatamente disponibili.",
			"ART_5.2": "2. Se le parti hanno concordato un pagamento anticipato, senza ulteriori indicazioni, si suppone che tale pagamento anticipato, salvo diverso accordo, si riferisca al prezzo totale, e che il pagamento anticipato debba essere ricevuto dalla banca del Venditore in fondi immediatamente disponibili almeno 30 giorni prima della data di spedizione concordata o della data più vicina all'interno del periodo di spedizione concordato. Se il pagamento anticipato è stato concordato solo per una parte del prezzo del contratto, le condizioni di pagamento per l'importo rimanente saranno determinate secondo le regole stabilite in questo articolo.",
			"ART_5.3": "3. Se le parti hanno concordato un pagamento tramite credito documentario, salvo diverso accordo, l'Acquirente dovrà provvedere all'emissione di un credito documentario a favore del Venditore da una banca rispettabile, in conformità con le Regole e Usanze Uniformi per i Crediti Documentari (UCP 600) pubblicate dalla Camera di Commercio Internazionale, da notificare almeno 30 giorni prima della data di spedizione concordata o almeno 30 giorni prima della data più vicina all'interno del periodo di spedizione concordato. Salvo diverso accordo, il credito documentario sarà pagabile a vista e consentirà trasbordi, ma non consegne parziali.",
			"ART_5.4": "4. Se le parti hanno concordato un pagamento tramite incasso documentario, salvo diverso accordo, i documenti saranno presentati contro pagamento (D/P) e la presentazione sarà comunque soggetta alle Regole Uniformi per gli Incassi (URC 522) pubblicate dalla Camera di Commercio Internazionale.",
			"ART_5.5": "5. Se le parti hanno concordato un pagamento garantito da una Bank Payment Obligation (BPO), salvo diverso accordo, l'Acquirente dovrà provvedere affinché il Venditore riceva una garanzia di pagamento in conformità con le condizioni di pagamento concordate, sotto forma di una Bank Payment Obligation emessa da una banca a favore della Banca del Venditore, soggetta alle regole UR BPO (Regole Uniformi per Bank Payment Obligations) pubblicate dalla Camera di Commercio Internazionale, da notificare almeno 30 giorni prima della data di spedizione concordata o almeno 30 giorni prima della data più vicina all'interno del periodo di spedizione concordato. Salvo diverso accordo, la Bank Payment Obligation sarà pagabile a vista e consentirà trasbordi, ma non consegne parziali.",
			"ART_5.6": "6. Nella misura in cui le parti abbiano concordato che il pagamento sia garantito da una garanzia bancaria, l'Acquirente deve fornire, almeno 30 giorni prima della data di spedizione concordata o almeno 30 giorni prima della data più vicina all'interno del periodo di spedizione concordato, una garanzia bancaria a prima richiesta soggetta alle Regole Uniformi per le Garanzie a Prima Richiesta (URDG 758) pubblicate dalla Camera di Commercio Internazionale, oppure una lettera di credito standby soggetta a tali Regole, alle International Standby Practices (ISP 98) o alle Regole e Usanze Uniformi per i Crediti Documentari (UCP 600) pubblicate dalla Camera di Commercio Internazionale, in entrambi i casi emessa da una banca rispettabile."
		},
		"ART_6": {
			"TITLE": "Art. 6 - Interessi in caso di pagamento ritardato",
			"ART_6.1": "1. Se una parte non paga una somma di denaro quando essa scade, l'altra parte ha diritto agli interessi su tale somma dal momento in cui il pagamento è dovuto fino al momento del pagamento.",
			"ART_6.2": "2. Salvo diverso accordo, il tasso di interesse sarà del 5% sopra il tasso medio di interesse a breve termine praticato dalle banche per i principali mutuatari, vigente per la valuta di pagamento nel luogo di pagamento, o, se tale tasso non esiste in quel luogo, allora lo stesso tasso nello Stato della valuta di pagamento."
		},
		"ART_7": {
			"TITLE": "Art. 7 - Riserva di proprietà",
			"TEXT": "Se una parte non paga una somma di denaro quando essa scade, l'altra parte ha diritto agli interessi su tale somma dal momento in cui il pagamento è dovuto fino al momento del pagamento."
		},
		"ART_8": {
			"TITLE": "Art. 8 - Termini contrattuali di consegna",
			"TEXT": "Se le parti hanno validamente concordato una riserva di proprietà, la merce rimarrà, nonostante la consegna e il passaggio del rischio sulla merce, di proprietà del Venditore fino al completo pagamento del prezzo, o come altrimenti concordato."
		},
		"ART_9": {
			"TITLE": "Art. 9 - Documenti",
			"TEXT": "Salvo diverso accordo, il Venditore deve fornire i documenti (se presenti) indicati nella regola Incoterms® applicabile o, se nessuna regola Incoterms® è applicabile, secondo qualsiasi corso precedente di trattative."
		},
		"ART_10": {
			"TITLE": "Art. 10 - Consegna in ritardo, mancata consegna e rimedi per tali casi",
			"ART_10.1": "1. Se le parti hanno concordato una data di cancellazione nella Casella A-9, l'Acquirente può dichiarare il contratto risolto notificando al Venditore nel caso in cui la consegna non sia avvenuta entro tale data di cancellazione per qualsiasi motivo (incluso un evento di forza maggiore).",
			"ART_10.2": "2. In caso di ritardo nella consegna di qualsiasi merce, l'Acquirente ha diritto a richiedere l'adempimento e danni liquidati pari allo 0,5% o a qualsiasi altra percentuale concordata del prezzo di tali merci per ogni settimana iniziata di ritardo. I danni liquidati per ritardo non potranno superare il 5% del prezzo delle merci in ritardo o l'importo massimo concordato nella Casella A-10.",
			"ART_10.3": "3. Quando l'articolo 10.1 non si applica e il Venditore non ha consegnato le merci entro la data in cui l'Acquirente ha acquisito il diritto al massimo importo di danni liquidati ai sensi dell'articolo 10.2, l'Acquirente può in qualsiasi momento richiedere l'adempimento o dichiarare il contratto risolto per iscritto.",
			"ART_10.4": "4. In caso di risoluzione del contratto ai sensi degli articoli 10.1 o 10.3, l'Acquirente ha diritto a richiedere danni che complessivamente non superino il prezzo delle merci non consegnate, o l'importo massimo concordato nella Casella A-11.",
			"ART_10.5": "5. I rimedi previsti da questo articolo escludono qualsiasi altro rimedio per ritardo nella consegna o mancata consegna."
		},
		"ART_11": {
			"TITLE": "Art. 11 - Non conformità delle merci",
			"ART_11.1": "1. L'Acquirente deve esaminare le merci il prima possibile dopo il loro arrivo presso la sede dell'Acquirente o qualsiasi altro luogo di esame concordato e deve notificare al Venditore per iscritto qualsiasi mancanza di conformità, specificando la natura della mancanza di conformità delle merci, entro un tempo ragionevole dalla data in cui l'Acquirente scopre o avrebbe dovuto scoprire la mancanza di conformità. In ogni caso, l'Acquirente non avrà diritto a rimedi per la mancanza di conformità se non notifica il Venditore entro 24 mesi dalla data di arrivo delle merci presso la sede dell'Acquirente o il luogo di esame concordato, se previsto.",
			"ART_11.2": "2. Le merci si considerano conformi al contratto nonostante lievi discrepanze che sono usuali nel commercio particolare o nel corso di trattative tra le parti.",
			"ART_11.3": "3. Quando le merci non sono conformi, il Venditore deve, a sua scelta e purché possa farlo senza ingiustificato ritardo e senza causare inconvenienti irragionevoli all'Acquirente: (a) sostituire le merci con merci conformi, senza alcuna spesa aggiuntiva per l'Acquirente, oppure (b) riparare le merci, senza alcuna spesa aggiuntiva per l'Acquirente. L'Acquirente avrà diritto a danni liquidati per il ritardo dovuto alla sostituzione o riparazione come specificato nell'articolo 10.2 o come concordato nella Casella A-10.",
			"ART_11.4": "4. Se il Venditore non ha adempiuto o ha rifiutato di adempiere correttamente ai suoi obblighi ai sensi dell'articolo 11.3 entro un periodo ragionevole, e salvo che le parti non abbiano concordato una riduzione del prezzo, l'Acquirente può ricorrere ai rimedi previsti dalla CISG in conformità con i termini stabiliti in questo contratto. Per i danni provati dall'Acquirente, l'importo massimo è limitato al prezzo concordato delle merci non conformi.",
			"ART_11.5": "5. Salvo diverso accordo scritto, i rimedi previsti da questo articolo 11 escludono qualsiasi altro rimedio per non conformità.",
			"ART_11.6": "6. Salvo diverso accordo scritto, nessuna azione per mancanza di conformità può essere intrapresa dall'Acquirente, sia davanti a tribunali giudiziari che arbitrali, dopo 4 anni dalla data di arrivo delle merci presso il luogo di esame. È espressamente concordato che dopo la scadenza di tale termine, l'Acquirente non potrà invocare la non conformità delle merci, né fare una contropretesa su di essa, in difesa di qualsiasi azione intrapresa dal Venditore contro l'Acquirente per inadempimento di questo contratto."
		},
		"ART_12": {
			"TITLE": "Art. 12 - Cooperazione tra le parti",
			"ART_12.1": "1. L'Acquirente deve informare tempestivamente il Venditore di qualsiasi reclamo presentato contro l'Acquirente dai propri clienti o da terzi riguardo alle merci consegnate o ai diritti di proprietà industriale o intellettuale ad esse correlati.",
			"ART_12.2": "2. Il Venditore informerà tempestivamente l'Acquirente di qualsiasi reclamo che possa coinvolgere la responsabilità del prodotto dell'Acquirente."
		},
		"ART_13": {
			"TITLE": "Art. 13 - Forza maggiore",
			"ART_13.1": "1. Una parte non è responsabile per la mancata esecuzione di uno qualsiasi dei suoi obblighi nella misura in cui dimostri (a) che la mancata esecuzione è dovuta a un impedimento al di fuori del suo controllo, e (b) che non poteva ragionevolmente prevedere l'impedimento e i suoi effetti sulla sua capacità di eseguire al momento della conclusione del contratto, e (c) che non poteva ragionevolmente evitare o superare l'impedimento o i suoi effetti.",
			"ART_13.2": "2. Una parte che chiede il rimedio deve, non appena possibile dopo che l'impedimento e i suoi effetti sulla capacità di eseguire di quella parte sono diventati noti, dare notifica all'altra parte di tale impedimento e dei suoi effetti sulla sua capacità di eseguire. La notifica deve essere data anche quando il motivo del rimedio cessa. La mancata notifica comporta la responsabilità della parte per i danni che altrimenti sarebbero stati evitati.",
			"ART_13.3": "3. Fatto salvo l'articolo 10.2, un motivo di rimedio ai sensi di questa clausola esonera la parte che non ha eseguito dall'obbligo di rispondere per i danni, dalle penalità e da altre sanzioni contrattuali, e dall'obbligo di pagare interessi sul denaro dovuto finché e nella misura in cui il motivo persista.",
			"ART_13.4": "4. Se i motivi di forza maggiore persistono per più di tre (3) mesi, una delle parti ha il diritto di dichiarare il contratto risolto senza preavviso."
		},
		"ART_14": {
			"TITLE": "Art. 14 - Risoluzione delle controversie",
			"ART_14.1": "1. Le parti possono in qualsiasi momento, senza pregiudizio per altri procedimenti, cercare di risolvere qualsiasi controversia derivante da o in relazione con il presente contratto in conformità con le Regole ADR della ICC.",
			"ART_14.2": "2. Salvo diverso accordo scritto, tutte le controversie derivanti da o in relazione con il presente contratto saranno sottoposte alla Corte Internazionale di Arbitrato della Camera di Commercio Internazionale e saranno risolte definitivamente secondo le Regole di Arbitrato della Camera di Commercio Internazionale da uno o più arbitri nominati in conformità con le suddette Regole.",
			"ART_14.3": "3. Una clausola arbitrale non impedisce a nessuna delle parti di richiedere misure provvisorie o conservatorie dai tribunali statali."
		}
	},
	"A1": {
		"TITLE": "A-1 Prodotti venduti",
		"TIP_MSG": "Assicurati di specificare chiaramente tutti i dettagli delle merci, compresi codice prodotto, origine, descrizione e quantità. Un errore o una vaghezza qui può causare problemi doganali o contestazioni da parte dell'acquirente. Includi dettagli come la voce doganale (o HS code) per facilitare la conformità alle normative di esportazione e importazione.",
		"Unnamed_product": "Prodotto senza nome",
		"Code": "Codice",
		"Product_code": "Codice prodotto",
		"Description": "Descrizione",
		"Product_description": "Descrizione prodotto",
		"Quantity": "Quantità",
		"Price": "Prezzo",
		"Origin": "Origine",
		"Product_origin": "Origine del prodotto",
		"HS_Code": "HS Code",
		"Product_HS_Code": "HS Code del prodotto",
		"Add_product": "Aggiungi prodotto",
		"Remove_product": "Rimuovi prodotto"
	},
	"A2": {
		"TITLE": "A-2 Prezzo contrattuale",
		"TIP_MSG": "Specifica la valuta del contratto e l’importo. Ricorda che Il prezzo può essere soggetto a fluttuazioni valutarie ed eventuali oneri e costi aggiuntivi come tasse locali, IVA o dazi doganali.",
		"Amount": "Importo",
		"Total_amount": "Importo totale"
	},
	"A3": {
		"TITLE": "A-3 Termini di consegna",
		"ALERT_MSG": "Termini raccomandati (conformemente agli Incoterms 1990): v. Introduzione, § 5)",
		"TIP_MSG": "Scegli attentamente i termini Incoterms® per definire chi è responsabile di trasporto e rischi durante la spedizione. Gli Incoterms® sono preferibili per il trasporto internazionale. Evita EXW e DDP a meno che non sia per transazioni all’interno della stessa area doganale.",
		"Terms_choose": "Scelta termini",
		"Shipping_carrier": "Vettore consegna",
		"Contact_person": "Persona di contatto",
		"Catalog": "Catalogo",
		"Select": "Seleziona...",
		"Term": "Termine",
		"Notes": "Note",
		"Place": "Luogo",
		"EXW": "EXW - Franco fabbrica",
		"FCA": "FCA - Franco vettore",
		"FAS": "FAS - Franco lungo bordo",
		"FOB": "FOB - Franco a bordo",
		"CFR": "CFR - Costo e nido",
		"CPT": "CPT - Costo e nolo",
		"CIF": "CIF - Costo, assicurazione e nolo",
		"CIP": "CIP - Trasporto e assicurazione pagati fino a",
		"CPT": "CPT - Trasporto pagato fino a",
		"DAF": "DAF - Reso frontiera",
		"DAP": "DAP - Reso nel luogo di destinazione convenuto",
		"DAT": "DAT - Reso al terminal",
		"DDP": "DDP - Reso sdoganato",
		"DDU": "DDU - Reso non sdoganato",
		"DEQ": "DEQ - Reso banchina",
		"DES": "DES - Reso ex ship",
		"DPU": "DPU - Consegnato in luogo scaricato"
	},
	"A4": {
		"TITLE": "A-4 Termine (data) di consegna",
		"ALERT_MSG": "Indicare qui la data o il periodo (ad es. settimana o mese) in cui o entro cui il Venditore è tenuto ad adempiere al suo obbligo di consegna conformemente alla clausola A4 del rispettivo Incoterm: v. Introduzione, § 6).",
		"TIP_MSG": "Inserisci una data precisa o un periodo per la consegna. Un’indicazione vaga può portare a ritardi e controversie. Definisci se il tempo di consegna è ‘entro’ o ‘non oltre’ una certa data. Assicurati che la data sia realistica considerando anche eventuali ostacoli come ritardi nelle operazioni doganali o le tempistiche necessarie per ottenere certificati, autorizzazioni o licenze.",
		"Shipping_date_min": "Data di consegna (min)",
		"Shipping_date_max": "Data di consegna (max)",
		"DATE_PLACEHOLDER": "gg/mm/AAAA"
	},
	"A5": {
		"TITLE": "A-5 Ispezione dei prodotti (Art. 3)",
		"TIP_MSG": "In questa clausola opzionale, si può prevedere un’ispezione prima della spedizione, in questo caso è consigliabile predisporre un mandato specifico dove si definiscano le regole dell’ispezione e gli obiettivi (per esempio: quantità dei beni, numero di lotti, etc.). Specificare il luogo e il momento dell’ispezione, oltre al responsabile del costo (venditore o acquirente), può prevenire controversie future. L'ispezione può avvenire prima o dopo la spedizione. È consigliabile effettuare l'ispezione nel Paese di origine per evitare costi di restituzione in caso di difetti. Da ricordare che l’ispezione è diversa dalla verifica/collaudo dei beni quando questi sono giunti nelle disponibilità del compratore.",
		"Inspection_type": "Tipo di ispezione",
		"Select": "Seleziona...",
		"Before_shipping": "Prima della spedizione",
		"Other": "Altro",
		"Notes": "Note"
	},
	"A6": {
		"TITLE": "A-6 Riserva di proprietà (Art. 7)",
		"TIP_MSG": "Questa clausola permette al venditore di mantenere la proprietà delle merci fino al pagamento completo. Tuttavia, in alcuni Paesi, la riserva di proprietà non è riconosciuta legalmente. Assicurati che la clausola sia valida nella giurisdizione locale.",
		"Retention_of_title": "Riserva di proprietà",
		"None": "Nessuna",
		"Simple": "Semplice",
		"Extended": "Estesa"
	},
	"A7": {
		"TITLE": "A-7 Condizioni di pagamento (Art. 5)",
		"TIP_MSG": "Definisci con precisione le condizioni di pagamento. È importante indicare il tipo di garanzia e tempistiche per minimizzare rischi di insolvenza.",
		"Payment_type": "Tipo di pagamento",
		"Delayed_payment": "Pagamento posticipato (art. 5.1)",
		"Delayed_payment_days": "Data di pagamento (se diversa dall'art. 5.1)",
		"DELAYED_PAYMENT_DAYS_TIPS": "Giorni dalla data della fattura",
		"Delayed_payment_other": "Altro",
		"DELAYED_PAYMENT_OTHER_TIPS": "Pagamento posticipato accompagnato da garanzia bancaria o standby letter of credit (art. 5.5)",
		"Prepayment": "Pagamento anticipato (art. 5.2)",
		"Prepayment_date": "Data di pagamento (se diversa dall'art. 5.2)",
		"Prepayment_percentage": "Percentuale del prezzo totale",
		"Payment_against_documents": "Pagamento contro documenti (art. 5.5)",
		"Payment_against_documents_type": "Pagamento contro documenti (art. 5.5)",
		"Documents_against_payment": "D/P Documenti contro pagamento",
		"Documents_against_acceptation": "D/A Documenti contro accettazione",
		"PAYMENT_OTHER_TIPS": "Es: assegno, tratta bancaria, trasferimento elettronico di fondi sul conto della banca designata dal venditore",
		"Irrevocable_documentary_credit": "Credito documentario irrevocabile (art. 5.3)",
		"Irrevocable_documentary_credit_issue_place": "Luogo di emissione (se applicabile)",
		"Irrevocable_documentary_credit_confirmation_place": "Luogo di conferma (se applicabile)",
		"Irrevocable_documentary_credit_use": "Credito utilizzabile per",
		"Sight_payment": "Pagamento a vista",
		"Deferred_payment": "Pagamento differito",
		"Acceptance_of_drafts": "Accettazione di tratte",
		"Negotiation": "Negoziazione",
		"Partial_shippings": "Spedizioni parziali",
		"Transhipments": "Trasbordi",
		"Notification_date": "Data di notifica credito",
		"NOTIFICATION_DATE_TIPS": "Data entro cui il credito documentario dev'essere notificato al venditore (se diversa dall'art. 5.3)",
		"DATE_PLACEHOLDER": "gg/mm/AAAA",
		"Days": "Giorni",
		"Percentage": "Percentuale",
		"Place": "Luogo",
		"Other": "Altro"
	},
	"A8": {
		"TITLE": "A-8 Documenti",
		"TIP_MSG": "Indica chiaramente i documenti richiesti per facilitare lo sdoganamento. Assicurati che i documenti siano in linea con i termini Incoterms® selezionati nella sezione A-3 delle presenti Condizioni Speciali. Verifica i requisiti formali che i documenti devono avere nel paese di destino dei beni in collaborazione con l’acquirente.",
		"Invoice": "Fattura commerciale",
		"DDT": "Documento di trasporto",
		"Insurance_document": "Documento di assicurazione",
		"Origin_certificate": "Certificato di origine",
		"Preferential_origin_certificate": "Certificato di origine preferenziale",
		"Inspection_certificate": "Certificato di ispezione",
		"Packing_list": "Packing_list",
		"Other": "Altro"
	},
	"A9": {
		"TITLE": "A-9 Data di risoluzione",
		"TIP_MSG": "Definire una data di cancellazione offre protezione all'acquirente in caso di ritardo eccessivo, ovvero in caso di forza maggiore. Se le merci non vengono consegnate entro la data stabilita, l'acquirente può dichiarare nullo il contratto.",
		"Termination_date": "Data di risoluzione",
		"DATE_PLACEHOLDER": "gg/mm/AAAA"
	},
	"A10": {
		"TITLE": "A-10 Responsabilità per ritardata consegna (Art. 10.1, 10.4, 11.3)",
		"ALERT_MSG": "DA COMPLETARE SOLO SE LE PARTI DESIDERANO MODIFICARE GLI ARTT. 10.1, 10.4 o 11.3.",
		"TIP_MSG": "Includi eventuali danni liquidati per ritardi, specificando la percentuale e la durata massima del ritardo accettabile. Definire penali per ritardi può disincentivare il venditore dal causare ritardi. Es. 0,5% a settimana fino a un massimo del 5% del prezzo dei beni in ritardo.",
		"LATE_DELIVERY_PENALTY_CTA": "La penale per ritardata consegna sarà della percentuale di seguito specificata (del prezzo dei prodotti consegnati in ritardo) per ogni settimana di ritardo, entro il limite massimo in percentuale di seguito specificato (del prezzo dei prodotti di cui sopra).",
		"EXCEED_AMOUNT_CTA": "In caso di risoluzione per ritardata consegna, la responsabilità del Venditore per danni da ritardata consegna è limitata alla percentuale di seguito specificata del prezzo dei prodotti non consegnati.",
		"SELLER_RESPONSABILITY_CTA": "La responsabilità del Venditore per danni derivanti da non conformità della merce sarà limitata al danno provato (incluso il danno consequenziale, mancato guadagno, ecc.) entro il limite massimo della percentuale di seguito specificata del prezzo del contratto.",
		"Price_percentage": "Percentuale del prezzo",
		"Max_limit_percentage": "Percentuale del limite massimo",
		"Seller_percentage": "Percentuale del venditore"
	},
	"A11": {
		"TITLE": "A-11 Limitazione di responsabilità per non conformità (Art. 11.5)",
		"ALERT_MSG": "DA COMPLETARE SOLO SE LE PARTI DESIDERANO MODIFICARE L'ART. 11.5",
		"TIP_MSG": "Definisci un limite massimo per i danni che il venditore deve pagare in caso di ritardo nella consegna. Questo può proteggere il venditore da richieste di risarcimento eccessive.",
		"PRICE_REDUCTION_PERCENTAGE_CTA": "La riduzione di prezzo per aver accettato prodotti non conformi non potrà eccedere la percentuale di seguito specificata del prezzo di tali prodotti.",
		"PRICE_REDUCTION_VALUE_CTA": "La riduzione di prezzo per aver accettato prodotti non conformi non potrà eccedere l'ammontare di seguito specificato.",
		"PRICE_REDUCTION_OTHER_CTA": "La riduzione di prezzo per aver accettato prodotti non conformi sarà gestita come di seguito specificato.",
		"Percentage": "Percentuale",
		"Amount": "Ammontare",
		"Other": "Altro"
	},
	"A12": {
		"TITLE": "A-12 Limitazione di responsabilità in caso il compratore decida di tenere prodotti non conformi (Art. 11.6)",
		"ALERT_MSG": "DA COMPLETARE SOLO SE LE PARTI DESIDERANO MODIFICARE L'ART. 11.6",
		"TIP_MSG": "Definisci un limite massimo per i danni che il venditore deve pagare in caso di merci non conformi al contratto. Questo può proteggere il venditore da richieste di risarcimento eccessive.",
		"EXCEED_PERCENTAGE_CTA": "La riduzione di prezzo per aver accettato prodotti  non conformi non potrà eccedere la percentuale (specificata) del prezzo di tali prodotti.",
		"EXCEED_AMOUNT_CTA": "La riduzione di prezzo per aver accettato prodotti  non conformi non potrà eccedere l'ammontare (specificata).",
		"Percentage": "Percentuale",
		"Amount": "Ammontare"
	},
	"A13": {
		"TITLE": "A-13 Termine di decadenza (Art. 11.8)",
		"ALERT_MSG": "DA COMPLETARE SOLO SE LE PARTI DESIDERANO MODIFICARE L'ART. 11.8",
		"TIP_MSG": "Stabilisci un termine entro il quale l'acquirente deve notificare al venditore eventuali difetti o non conformità delle merci. Questo protegge il venditore da reclami tardivi.",
		"DAYS_MSG": "Qualsiasi azione per non conformità dei prodotti (come definita all'articolo 11.8) dev'essere promossa dal Venditore entro i giorni dalla data di arrivo dei prodotti a destinazione specificati di seguito.",
		"Days": "Days"
	},
	"A14": {
		"TITLE": "A-14 Legge applicabile (Art. 1.2)",
		"ALERT_MSG": "Da completare solo se le parti desiderano sottoporre il contratto di vendita ad una legge nazionale invece che alla CVIM. La soluzione indicata qui sotto non è raccomandabile (v. Introduzione, § 3).",
		"TIP_MSG": "La scelta della legge applicabile può influenzare i rimedi in caso di controversia; verifica se applicare la legge del Paese del venditore o dell'acquirente. Se non diversamente specificato, si applicherà la legge del Paese del venditore. La Convenzione di Vienna (CISG) è spesso usata per uniformare i contratti di vendita internazionali.",
		"LAW_MSG": "Il presente contratto di vendita è sottoposto alla legge interna seguente.",
		"LAW2_MSG": "Qualsiasi questione non coperta dalla CVIM sarà regolata dalla legge seguente.",
		"Law": "Legge"
	},
	"A15": {
		"TITLE": "A-15 Risoluzione delle controversie (Art. 14)",
		"ALERT_MSG": "Le due soluzioni indicate sotto (arbitrato o tribunali ordinari) sono alternative: le parti non possono sceglierle ambedue. In assenza di scelta si applicherà l'arbitrato della CCI, conformemente all'art. 14.",
		"TIP_MSG": "Seleziona tra arbitrato o tribunale ordinario per risolvere le dispute; l’arbitrato può essere più rapido e meno costoso a lungo termine. L’arbitrato, regolato dalla Corte Internazionale dell’ICC, può essere più efficace per controversie internazionali. Specifica la sede e la lingua dell’arbitrato per evitare ambiguità.",
		"Dispute_resolution": "Risoluzione delle controversie",
		"OPTION_0": "ADR - Risoluzione alternativa delle controversie",
		"OPTION_1": "ARBITRATO",
		"OPTION_2": "CCI (conformemente all'art. 14.1)",
		"OPTION_3": "Altro (specificare)",
		"OPTION_4": "Giurisdizione ordinaria (specificare)",
		"Other": "Altro",
		"Competent_court": "Corte competente"
	},
	"A16": {
		"TITLE": "A-16 Altro",
		"TIP_MSG": "Utilizza questo spazio per inserire qualsiasi altra informazione o clausola che non rientra nelle sezioni precedenti. Assicurati che tutte le clausole aggiuntive siano chiare e non contraddicano le altre condizioni del contratto.",
		"Other": "Altro"
	},
	"accountPage": {
		"My_account": "Il mio account",
		"Anonymous_identity": "Identità anonima",
		"My_contracts": "I miei contratti",
		"Add": "Aggiungi",
		"Contract_model": "Modello di contratto",
		"My_role": "Il mio ruolo",
		"Seller": "Venditore",
		"Buyer": "Compratore",
		"Contract_name": "Nome del contratto",
		"Create_contract": "Crea contratto"
	},
	"editAccountPage": {
		"Name": "Nome",
		"Surname": "Cognome",
		"Email": "Email",
		"Phone": "Telefono",
		"PLACEHOLDER_name": "Il tuo nome",
		"PLACEHOLDER_surname": "Il tuo cognome",
		"PLACEHOLDER_email": "La tua email",
		"PLACEHOLDER_phone": "Il tuo telefono"
	},
	"viewContractPage": {
		"View_contract": "Visualizza contratto",
		"Contract_draft": "Contratto in bozza",
		"CONTRACT_DRAFT_MSG": "Questo contratto è una bozza. Salvalo sulla blockchain per storicizzarlo in maniera persistente e sicura tramite sistema blockchain.",
		"Store_contract": "Salva contratto",
		"Contract_saved": "Contratto salvato",
		"CONTRACT_SAVED_MSG": "Questo contratto è stato storicizzato in maniera persistente e sicura tramite sistema blockchain ed è pronto per essere firmato.",
		"Sign_contract": "Firma contratto",
		"Contract_signed": "Contratto firmato",
		"CONTRACT_SIGNED_MSG": "Questo contratto è stato storicizzato e firmato in maniera persistente e sicura tramite sistema blockchain. La merce è pronta per essere spedita.",
		"Contract_status": "Stato del contratto",
		"Saved": "Salvato",
		"Signed": "Firmato",
		"Shipped": "Spedito",
		"Delivered": "Consegnato",
		"Completed": "Completato",
		"Payment_status": "Stato del pagamento",
		"Contract_details": "Dettagli del contratto",
		"Contract_id": "ID del contratto",
		"Contract_name": "Nome del contratto",
		"Contract_model": "Modello di contratto",
		"Contract_value": "Valore del contratto",
		"Seller": "Venditore",
		"Buyer": "Compratore",
		"Seller_signature": "Firma del venditore",
		"Buyer_signature": "Firma del compratore",
		"Contract_id_on_blockchain": "ID del contratto sulla blockchain",
		"Verify": "Verifica",
		"Change_log": "Registro delle modifiche",
		"Open_PDF": "Apri PDF"
	},
	"viewContractChangesPage": {
		"Change_log": "Registro delle modifiche",
		"Last_changes" : "Ultime modifiche",
		"LAST_CHANGES_DESCRIPTION": "Qui trovi tutte le modifiche effettuate al contratto"
	},
	"shareContractPage": {
		"Share_your_contract": "Condividi il tuo contratto",
		"SHARE_MSG": "Scrivi un messaggio e condividi il tuo contratto",
		"SHARE_TEXTAREA_MSG": "Ciao! Ecco il contratto che ho inizializzato, clicca per aggiungerlo al tuo account TradeOnChain!",
		"SHARE_CTA": "Condividi",
		"URL_MSG": "Link di condivisione del contratto",
		"URL_CTA": "Copia link",
		"QRCODE_MSG": "QR Code di condivisione del contratto"
	},
	"addContractPage": {
		"Add_contract": "Aggiungi contratto",
		"ADD_CONTRACT_MSG": "Clicca sul pulsante 'Aggiungi' per aggiungere questo contratto al tuo account e collaborare alla stesura."
	},
	"languages": {
		"Italian": "Italiano",
		"English": "Inglese"
	},
	"countries": {
		"af": "Afghanistan",
        "al": "Albania",
        "dz": "Algeria",
        "ad": "Andorra",
        "ao": "Angola",
        "ar": "Argentina",
        "am": "Armenia",
        "au": "Australia",
        "at": "Austria",
        "az": "Azerbaigian",
        "bh": "Bahrein",
        "bd": "Bangladesh",
        "by": "Bielorussia",
        "be": "Belgio",
        "bz": "Belize",
        "bj": "Benin",
        "bt": "Bhutan",
        "bo": "Bolivia",
        "ba": "Bosnia-Erzegovina",
        "bw": "Botswana",
        "br": "Brasile",
        "bn": "Brunei",
        "bg": "Bulgaria",
        "bf": "Burkina Faso",
        "bi": "Burundi",
        "kh": "Cambogia",
        "cm": "Camerun",
        "ca": "Canada",
        "cv": "Capo Verde",
        "td": "Ciad",
        "cl": "Cile",
        "cn": "Cina",
        "co": "Colombia",
        "cg": "Congo",
        "cr": "Costa Rica",
        "hr": "Croazia",
        "cu": "Cuba",
        "cy": "Cipro",
        "cz": "Repubblica Ceca",
        "dk": "Danimarca",
        "dj": "Gibuti",
        "do": "Repubblica Dominicana",
        "ec": "Ecuador",
        "eg": "Egitto",
        "sv": "El Salvador",
        "ee": "Estonia",
        "et": "Etiopia",
        "fi": "Finlandia",
        "fr": "Francia",
        "ga": "Gabon",
        "gm": "Gambia",
        "ge": "Georgia",
        "de": "Germania",
        "gh": "Ghana",
        "gr": "Grecia",
        "gt": "Guatemala",
        "gn": "Guinea",
        "ht": "Haiti",
        "hn": "Honduras",
        "hu": "Ungheria",
        "is": "Islanda",
        "in": "India",
        "id": "Indonesia",
        "ir": "Iran",
        "iq": "Iraq",
        "ie": "Irlanda",
        "il": "Israele",
        "it": "Italia",
        "jm": "Giamaica",
        "jp": "Giappone",
        "jo": "Giordania",
        "kz": "Kazakistan",
        "ke": "Kenya",
        "kr": "Corea del Sud",
        "kw": "Kuwait",
        "lv": "Lettonia",
        "lb": "Libano",
        "lr": "Liberia",
        "ly": "Libia",
        "lt": "Lituania",
        "lu": "Lussemburgo",
        "mg": "Madagascar",
        "my": "Malesia",
        "ml": "Mali",
        "mt": "Malta",
        "mx": "Messico",
        "mn": "Mongolia",
        "me": "Montenegro",
        "ma": "Marocco",
        "mz": "Mozambico",
        "mm": "Myanmar",
        "np": "Nepal",
        "nl": "Paesi Bassi",
        "nz": "Nuova Zelanda",
        "ng": "Nigeria",
        "no": "Norvegia",
        "om": "Oman",
        "pk": "Pakistan",
        "pa": "Panama",
        "py": "Paraguay",
        "pe": "Perù",
        "ph": "Filippine",
        "pl": "Polonia",
        "pt": "Portogallo",
        "qa": "Qatar",
        "ro": "Romania",
        "ru": "Russia",
        "rw": "Ruanda",
        "sa": "Arabia Saudita",
        "sn": "Senegal",
        "rs": "Serbia",
        "sg": "Singapore",
        "sk": "Slovacchia",
        "si": "Slovenia",
        "za": "Sudafrica",
        "es": "Spagna",
        "lk": "Sri Lanka",
        "se": "Svezia",
        "ch": "Svizzera",
        "sy": "Siria",
        "tw": "Taiwan",
        "th": "Thailandia",
        "tn": "Tunisia",
        "tr": "Turchia",
        "ua": "Ucraina",
        "ae": "Emirati Arabi Uniti",
        "gb": "Regno Unito",
        "us": "Stati Uniti",
        "uy": "Uruguay",
        "ve": "Venezuela",
        "vn": "Vietnam",
        "zm": "Zambia",
        "zw": "Zimbabwe"
	}
}