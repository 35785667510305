{
	"general" : {
		"Add": "Add",
		"Cancel": "Cancel",
		"Edit": "Edit",
		"Share": "Share",
		"View": "View",
		"Loading": "Loading...",
		"Select": "Select...",

		"APP_Language": "APP Language",
		"International_sale_contract": "International sales contract",
		"INTERNATION_SALE_CONTRACT_DESCRIPTION": "ICC International Sales Contract (Manufactured Products for Resale)",
		"PRODUCT_EXPORT_ALERT_MSG": "WARNING! Special authorizations are required to export the indicated products to the destination country. Make sure you have all authorizations before concluding the contract.",
		"Save": "Save",
		
		"Business_name": "Business name",
		"Vat_code": "VAT number",
		"Street_or_Square": "Street or Square",
		"Country": "State",
		"City": "City",
		"Provinces": "Province",
		"Postal_code": "ZIP",
		
		"Data": "Data",
		"Seller": "Seller",
		"Buyer": "Buyer",
		"Legal_representative": "Legal representative",
		"Contact_person": "Contact person",
		"Name": "Name",
		"Surname": "Surname",
		"Email": "Email",
		"Phone": "Phone",
		"Signatory": "Signatory",
		"SIGNATORY_TIP": "Specifies whether the legal representative is also a signatory of the contract.",
		"Personal_responsibility": "Personal responsibilities",
		"PERSONAL_RESPONSIBILITY_TIP": "Specifies whether the legal representative is personally liable for this contract. Otherwise, the responsibility is assumed to fall on the company.",
		
		
		"Login_to_your_account": "Login to your account",
		"Manage_your_contracts": "Manage your contracts",
		"Password": "Password",
		"PLACEHOLDER_Email": "Your email",
		"PLACEHOLDER_Password": "Your password",

		"Terms": "General conditions",
		"TERMS_DESCRIPTION": "All the general conditions must be specified here.",
		
		"Contract_saved_title": "Contract saved!",
		"Contract_saved_subtitle": "The transaction has been stored on Blockchain",
			
		"VALIDATION_REQUIRED_FIELD": "Required field.",
		"VALIDATION_PHONE_FIELD": "Required field. Must contain 8 to 12 digits."
	},
	"intro": {
		"WELCOME": "Welcome to filling out the international sales contract!",
		"SELLER": "The seller's details will be entered here.",
		"BUYER": "Buyer's here!",
		"GENERAL_TERMS": "In this block you will find the general conditions of the contract.",
		"SPECIAL_TERMS": "In all subsequent blocks you will find the special conditions of the contract."
	},
	"terms": {
		"TIP_MSG": "In questa sezione trovi le condizioni generali del contratto. Queste condizioni si applicano a tutti i contratti di vendita internazionale, a meno che non vengano specificate condizioni speciali. Questo modello si basa sulla <a href='https://uncitral.un.org/sites/uncitral.un.org/files/media-documents/uncitral/en/19-09951_e_ebook.pdf' target='_blank'>Convenzione di Vienna (CISG)</a>, la quale stabilisce regole chiare su obblighi di venditore e acquirente, consegna, pagamento e molto altro. È applicata automaticamente in molti paesi (<a href='https://treaties.un.org/Pages/ViewDetails.aspx?src=TREATY&mtdsg_no=X-10&chapter=10&clang=_en' target='blank'>verifica se il tuo paese rientra tra quelli dove si applica</a>), a meno che non sia espressamente esclusa.",
		"ART_1": {
			"TITLE": "Art. 1 - General",
			"ART_1.1": "1. These General Conditions are intended to be applied together with the Specific Conditions (Part A) of the ICC Model International Sale Contract (Manufactured Goods), but they may also be incorporated on their own into any sale contract. Where these General Conditions (Part B) are used independently of the said Specific Conditions (Part A), any reference in Part B to Part A will be interpreted as a reference to any relevant specific conditions agreed by the parties. In case of contradiction between these General Conditions and any specific conditions agreed upon between the parties, the specific conditions shall prevail.",
			"ART_1.2": "2. Qualsiasi questione relativa al presente contratto che non sia risolta dalle disposizioni contenute nel contratto stesso (cioè le presenti Condizioni Generali e qualsiasi condizione specifica concordata dalle parti) sarà regolata: A. dalla Convenzione delle Nazioni Unite sui contratti di vendita internazionale di merci (Convenzione di Vienna del 1980, di seguito denominata CISG), e B. nella misura in cui tali questioni non siano coperte dalla CISG e non sia stata concordata alcuna legge applicabile, con riferimento alla legge del paese in cui il Venditore ha la sua sede di attività.",
			"ART_1.3": "3. Any reference made to a publication of the International Chamber of Commerce is deemed to be made to the version current at the date of conclusion of the contract.",
			"ART_1.4": "4. No modification of the contract is valid unless agreed or evidenced in writing. However, a party may be precluded by its conduct from asserting this provision to the extent that the other party has relied on that conduct.",
			"ART_1.5": "5. Any limitation to remedies in case of breach of contract shall be ineffective in cases of fraud or gross negligence of the breaching party."
		},
		"ART_2": {
			"TITLE": "Art. 2 - Characteristics of the goods",
			"ART_2.1": "1. It is agreed that any information relating to the goods and their use, such as weights, dimensions, capacities, prices, colours and other data contained in catalogues, prospectuses, circulars, advertisements, illustrations, price-lists of the Seller, shall not take effect as terms of the contract unless expressly referred to in the contract.",
			"ART_2.2": "2. Unless otherwise agreed, the Buyer does not acquire any property rights in software, drawings, etc. which may have been made available to it. The Seller also remains the exclusive owner of any intellectual or industrial property rights relating to the goods.",
			"ART_2.3": "3. It is agreed that the goods are suitable for the purpose for which they are intended by their very nature or which is evident from the contract of sale.",
			"ART_2.4": "4. If express reference is made in the contract of sale to technical, safety, quality or other regulations and documents clearly designated in the contract, even if not attached to the contract, the Seller shall be deemed to have knowledge of these. The Seller shall bear the costs related to, and obtain the necessary permission, permits or licenses in good time required for carrying out of the agreement and for complying with the conditions stipulated therein."
		},
		"ART_3": {
			"TITLE": "Art. 3 - Inspection of the goods before shipment",
			"TEXT": "If the parties have agreed that the Buyer is entitled to inspect the goods before shipment, the Seller must notify the Buyer within a reasonable time before the shipment that the goods are ready for inspection at the agreed place."
		},
		"ART_4": {
			"TITLE": "Art. 4 - Price",
			"ART_4.1": "1. The price indicated under A-2 (contract price) includes any costs which are at the Seller's charge according to this contract. However, should the Seller bear any costs which, according to this contract, are for the Buyer's account (e.g. for transportation or insurance under FCA, EXW, FAS or FOB), such sums shall not be considered as having been included in the price under A-2.",
			"ART_4.2": "2. If no price has been agreed, the Seller's current list price at the time of the conclusion of the contract shall apply. In the absence of such a current list price, the price generally charged for such goods at the time of the conclusion of the contract in the Seller’s currency shall apply.",
			"ART_4.3": "3. Unless otherwise agreed in writing, the price does not include indirect taxes (VAT, sales tax, excise duties, ...), and is not subject to price adjustment."
		},
		"ART_5": {
			"TITLE": "Art. 5 - Payment conditions",
			"ART_5.1": "1. Unless otherwise agreed in writing, or implied from a prior course of dealing between the parties, payment of the price and of any other sums due by the Buyer to the Seller shall be on open account and time of payment shall be 30 days from the date of invoice. The amounts due shall be transferred, unless otherwise agreed, by telegraphic transfer or remittance to the Seller’s bank in the Seller’s country for the account of the Seller and the Buyer shall be deemed to have performed its payment obligations when the respective sums due have been received by the Seller's bank in immediately available funds.",
			"ART_5.2": "2. If the parties have agreed on payment in advance, without further indication, it will be assumed that such advance payment, unless otherwise agreed, refers to the full price, and that the advance payment must be received by the Seller's bank in immediately available funds at least 30 days before the agreed date of shipment or the earliest date within the agreed shipment period. If advance payment has been agreed only for a part of the contract price, the payment conditions of the remaining amount will be determined according to the rules set forth in this article.",
			"ART_5.3": "3. If the parties have agreed on payment by documentary credit, then, unless otherwise agreed, the Buyer must arrange for a documentary credit in favour of the Seller to be issued by a reputable bank, subject to the Uniform Customs and Practice for Documentary Credits (UCP 600) published by the International Chamber of Commerce, and to be notified at least 30 days before the agreed date of shipment or at least 30 days before the earliest date within the agreed shipment period. Unless otherwise agreed, the documentary credit shall be payable at sight and allow transhipments but no partial deliveries.",
			"ART_5.4": "4. If the parties have agreed on payment by documentary collection, then, unless otherwise agreed, documents will be tendered against payment (D/P) and the tender will in any case be subject to the Uniform Rules for Collections (URC 522) published by the International Chamber of Commerce.",
			"ART_5.5": "5. If the parties have agreed on payment against the security of a Bank Payment Obligation, then, unless otherwise agreed, the Buyer must arrange for the Seller to receive an assurance of payment in accordance with the agreed payment terms in the form of a Bank Payment Obligation to be issued by a bank in favour of the Seller’s Bank, subject to the UR BPO rules (Uniform Rules for Bank Payment Obligations) published by the International Chamber of Commerce, and to be notified at least 30 days before the agreed date of shipment or at least 30 days before the earliest date within the agreed shipment period. Unless otherwise agreed, the Bank Payment Obligation shall be payable at sight and allow transhipments but no partial deliveries.",
			"ART_5.6": "6. To the extent that the parties have agreed that payment is to be backed by a bank guarantee, the Buyer is to provide, at least 30 days before the agreed date of shipment or at least 30 days before the earliest date within the agreed shipment period, a first demand bank guarantee subject to the Uniform Rules for Demand Guarantees (URDG 758) published by the International Chamber of Commerce, or a standby letter of credit subject either to such Rules, to the International Standby Practices (ISP 98) or to the Uniform Customs and Practice for Documentary Credits (UCP 600) published by the International Chamber of Commerce, in either case issued by a reputable bank."
		},
		"ART_6": {
			"TITLE": "Art. 6 - Interest in case of delayed payment",
			"ART_6.1": "1. If a party does not pay a sum of money when it falls due the other party is entitled to interest upon that sum from the time when payment is due to the time of payment.",
			"ART_6.2": "2. Unless otherwise agreed, the rate of interest shall be 5% above the average bank short-term lending rate to prime borrowers prevailing for the currency of payment at the place of payment, or where no such rate exists at that place, then the same rate in the State of the currency of payment."
		},
		"ART_7": {
			"TITLE": "Art. 7 - Retention of title",
			"TEXT": "If a party does not pay a sum of money when it falls due the other party is entitled to interest upon that sum from the time when payment is due to the time of payment."
		},
		"ART_8": {
			"TITLE": "Art. 8 - Contractual term of delivery",
			"TEXT": "If the parties have validly agreed on retention of title, the goods shall, notwithstanding delivery and the passing of risk in the goods, remain the property of the Seller until the complete payment of the price, or as otherwise agreed."
		},
		"ART_9": {
			"TITLE": "Art. 9 - Documents",
			"TEXT": "Unless otherwise agreed, the Seller must provide the documents (if any) indicated in the applicable Incoterms® rule or, if no Incoterms® rule is applicable, according to any previous course of dealing."
		},
		"ART_10": {
			"TITLE": "Art. 10 - Late-delivery, non-delivery and remedies therefore",
			"ART_10.1": "1. If the parties have agreed upon a cancellation date in Box A-9, the Buyer may declare the contract avoided by notification to the Seller in case delivery has not occurred by such cancellation date for any reason whatsoever (including a force majeure event).",
			"ART_10.2": "2. When there is delay in delivery of any goods, the Buyer is entitled to claim performance and liquidated damages equal to 0,5% or such other percentage as may be agreed of the price of those goods for each commenced week of delay. Liquidated damages for delay shall not exceed 5% of the price of the delayed goods or such maximum amount as may be agreed in Box A-10.",
			"ART_10.3": "3. When article 10.1 does not apply and the Seller has not delivered the goods by the date on which the Buyer has become entitled to the maximum amount of liquidated damages under article 10.2, the Buyer may at any time ask for performance or declare the contract to be avoided in writing",
			"ART_10.4": "4. In case of avoidance of the contract under article 10.1 or 10.3 the Buyer is entitled to claim damages which in the aggregate do not exceed the price of the non-delivered goods, or such maximum amount as may be agreed in box A-11.",
			"ART_10.5": "5. The remedies under this article exclude any other remedy for delay in delivery or non-delivery."
		},
		"ART_11": {
			"TITLE": "Art. 11 - Non-conformity of the goods",
			"ART_11.1": "1. The Buyer shall examine the goods as soon as possible after their arrival at the place of business of the Buyer or any other agreed place of examination and shall notify the Seller in writing of any lack of conformity, specifying the nature of the lack of conformity of the goods within a reasonable time from the date when the Buyer discovers or ought to have discovered the lack of conformity. In any case the Buyer shall have no remedy for lack of conformity if it fails to notify the Seller thereof within 24 months from the date of arrival of the goods at the place of business of the Buyer or the otherwise agreed place of examination, if any.",
			"ART_11.2": "2. Goods will be deemed to conform to the contract despite minor discrepancies which are usual in the particular trade or through course of dealing between the parties.",
			"ART_11.3": "3. Where goods are non-conforming, the Seller shall at its option and provided it can do so without unreasonable delay and without causing the buyer unreasonable inconvenience: (a) replace the goods with conforming goods, without any additional expense to the Buyer, or (b) repair the goods, without any additional expense to the Buyer. The Buyer will be entitled to liquidated damages for the delay due to replacement or repair as specified under article 10.2or as may be agreed in Box A-10.",
			"ART_11.4": "4. If the Seller has failed or refused to properly perform its duties under article 11.3 within a reasonable period, and provided the parties have not agreed on a price reduction, the Buyer may resort to the remedies provided for by the CISG having regard to the terms laid down in this contract. As to the damages proven by the Buyer the maximum amount is limited the contractually agreed price of the non-conforming goods.",
			"ART_11.5": "5. Unless otherwise agreed in writing, the remedies under this article 11 exclude any other remedy for non-conformity.",
			"ART_11.6": "6. Unless otherwise agreed in writing, no action for lack of conformity can be taken by the Buyer, whether before judicial or arbitral tribunals, after 4 years from the date of arrival of the goods at the place of examination. It is expressly agreed that after the expiry of such term, the Buyer will not plead non-conformity of the goods, or make a counter-claim thereon, in defence to any action taken by the Seller against the Buyer for non-performance of this contract."
		},
		"ART_12": {
			"TITLE": "Art. 12 - Cooperation between the parties",
			"ART_12.1": "1. The Buyer shall promptly inform the Seller of any claim made against the Buyer by its customers or third parties concerning the goods delivered or industrial or intellectual property rights related thereto.",
			"ART_12.2": "2. The Seller will promptly inform the Buyer of any claim which may involve the product liability of the Buyer."
		},
		"ART_13": {
			"TITLE": "Art. 13 - Force majeure",
			"ART_13.1": "1. A party is not liable for a failure to perform any of its obligations in so far as it proves (a) that the failure was due to an impediment beyond its control, and (b) that it could not reasonably be expected to have taken the impediment and its effects upon its ability to perform into account at the time of the conclusion of the contract, and (c) that it could not reasonably have avoided or overcome the impediment or its effects.",
			"ART_13.2": "2. A party seeking relief shall, as soon as practicable after the impediment and its effects upon that party’s ability to perform become known to it, give notice to the other party of such impediment and its effects on his ability to perform. Notice shall also be given when the ground of relief ceases. Failure to give either notice makes the party thus failing liable in damages for loss which otherwise could have been avoided.",
			"ART_13.3": "3. Without prejudice to article 10.2, a ground of relief under this clause relieves the party failing to perform from liability in damages, from penalties and other contractual sanctions, and from the duty to pay interest on money owing as long as and to the extent that the ground subsists.",
			"ART_13.4": "4. If the grounds of relief subsist for more than three (3) months, either party shall be entitled to declare the contract to be avoided without notice."
		},
		"ART_14": {
			"TITLE": "Art. 14 - Resolution of disputes",
			"ART_14.1": "1. The parties may at any time, without prejudice to any other proceedings, seek to settle any dispute arising out of or in connection with the present contract in accordance with the ICC ADR Rules.",
			"ART_14.2": "2. Unless otherwise agreed in writing, all disputes arising out or in connection with the present contract shall be submitted to the International Court of Arbitration of the International Chamber of Commerce and shall be finally settled under the Rules of Arbitration of the International Chamber of Commerce by one or more arbitrators appointed in accordance with the said Rules.14.3 An arbitration clause does not prevent any party from requesting interim or conservatory measures from state courts."
		}
	},
	"A1": {
		"TITLE": "A-1 Products sold",
		"TIP_MSG": "Make sure to clearly specify all product details, including product code, origin, description, and quantity. Any error or ambiguity here can lead to customs issues or disputes from the buyer. Include details such as the customs tariff code (or HS code) to facilitate compliance with export and import regulations.",
		"Unnamed_product": "Unnamed product",
		"Code": "Code",
		"Product_code": "Product code",
		"Description": "Description",
		"Product_description": "Product description",
		"Quantity": "Quantity",
		"Price": "Price",
		"Origin": "Origin",
		"Product_origin": "Product origin",
		"HS_Code": "HS Code",
		"Product_HS_Code": "Product HS Code",
		"Add_product": "Add product",
		"Remove_product": "Remove product"
	},
	"A2": {
		"TITLE": "A-2 Contract Price",
		"TIP_MSG": "Specify the contract currency and amount. Remember that the price may be subject to exchange rate fluctuations and additional charges, such as local taxes, VAT, or customs duties.",
		"Amount": "Amount",
		"Total_amount": "Total amount"
	},
	"A3": {
		"TITLE": "A-3 Shipping Terms",
		"ALERT_MSG": "Recommended terms (according to Incoterms 1990): see Introduction, § 5)",
		"TIP_MSG": "Choose Incoterms® carefully to define who is responsible for transportation and risks during shipment. Incoterms® are preferable for international transportation. Avoid EXW and DDP unless for transactions within the same customs area.",
		"Terms_choose": "Terms choice",
		"Shipping_carrier": "Shipping carrier",
		"Contact_person": "Contact person",
		"Catalog": "Catalog",
		"Select": "Select...",
		"Term": "Term",
		"Notes": "Notes",
		"Place": "Place",
		"EXW": "EXW - Ex Works",
		"FCA": "FCA - Free Carrier",
		"FAS": "FAS - Free Alongside",
		"FOB": "FOB - Free on Board",
		"CFR": "CFR - Cost and Nest",
		"CPT": "CPT - Cost and Freight",
		"CIF": "CIF - Cost, insurance and freight",
		"CIP": "CIP - Freight and insurance paid up to",
		"CPT": "CPT - Freight paid until",
		"DAF": "DAF - Delivered at border",
		"DAP": "DAP - Delivered at the named place of destination",
		"DAT": "DAT - Return to Terminal",
		"DDP": "DDP - Delivered customs paid",
		"DDU": "DDU - Returned without customs duty",
		"DEQ": "DEQ - Dock Return",
		"DES": "DES - Returned ex ship",
		"DPU": "DPU - Delivered to unloaded location"
	},
	"A4": {
		"TITLE": "A-4 Delivery deadline",
		"ALERT_MSG": "Indicate here the date or period (e.g. week or month) on or by which the Seller is required to fulfill its delivery obligation in accordance with clause A4 of the respective Incoterm: see Introduction, § 6 )",
		"TIP_MSG": "Enter a specific date or timeframe for delivery. A vague indication can lead to delays and disputes. Define whether the delivery time is 'by' or 'no later than' a certain date. Ensure the date is realistic, considering possible obstacles like customs delays or the time needed to obtain certificates, authorizations, or licenses.",
		"Shipping_date_min": "Delivery date (min)",
		"Shipping_date_max": "Delivery date (max)",
		"DATE_PLACEHOLDER": "dd/mm/YYYY"
	},
	"A5": {
		"TITLE": "A-5 Product Inspection (Art. 3)",
		"TIP_MSG": "In this optional clause, a pre-shipment inspection can be provided; it is advisable to set up a specific mandate that defines the inspection rules and objectives (e.g., quantity of goods, number of batches, etc.). Specifying the location and time of the inspection, along with the party responsible for the cost (seller or buyer), can prevent future disputes. Inspection can take place before or after shipment. It is advisable to conduct the inspection in the country of origin to avoid return costs in case of defects. Note that inspection is different from the verification/testing of goods when they are in the buyer's possession.",
		"Inspection_type": "Inspection type",
		"Select": "Select...",
		"Before_shipping": "Before shipping",
		"Other": "Other",
		"Notes": "Notes"
	},
	"A6": {
		"TITLE": "A-6 Retention of title (Art. 7)",
		"TIP_MSG": "This clause allows the seller to retain ownership of the goods until full payment is made. However, in some countries, retention of title is not legally recognized. Ensure that the clause is valid in the local jurisdiction.",
		"Retention_of_title": "Retention of title",
		"None": "None",
		"Simple": "Simple",
		"Extended": "Extended"
	},
	"A7": {
		"TITLE": "A-7 Payment Conditions (Art. 5)",
		"TIP_MSG": "Precisely define the payment terms. It is important to indicate the type of guarantee and timing to minimize the risk of non-payment.",
		"Payment_type": "Payment Type",
		"Delayed_payment": "Deferred Payment (Art. 5.1)",
		"Delayed_payment_days": "Payment Date (if different from Art. 5.1)",
		"DELAYED_PAYMENT_DAYS_TIPS": "Days from the invoice date",
		"Delayed_payment_other": "Other",
		"DELAYED_PAYMENT_OTHER_TIPS": "Deferred payment accompanied by a bank guarantee or standby letter of credit (Art. 5.5)",
		"Prepayment": "Advance Payment (Art. 5.2)",
		"Prepayment_date": "Payment Date (if different from Art. 5.2)",
		"Prepayment_percentage": "Percentage of the total price",
		"Payment_against_documents": "Payment Against Documents (Art. 5.5)",
		"Payment_against_documents_type": "Payment Against Documents (Art. 5.5)",
		"Documents_against_payment": "D/P Documents Against Payment",
		"Documents_against_acceptation": "D/A Documents Against Acceptance",
		"PAYMENT_OTHER_TIPS": "E.g.: check, bank draft, electronic funds transfer to the seller's designated bank",
		"Irrevocable_documentary_credit": "Irrevocable Documentary Credit (Art. 5.3)",
		"Irrevocable_documentary_credit_issue_place": "Issuance Place (if applicable)",
		"Irrevocable_documentary_credit_confirmation_place": "Confirmation Place (if applicable)",
		"Irrevocable_documentary_credit_use": "Usable Credit for",
		"Sight_payment": "Sight Payment",
		"Deferred_payment": "Deferred Payment",
		"Acceptance_of_drafts": "Acceptance of Drafts",
		"Negotiation": "Negotiation",
		"Partial_shippings": "Partial Shipments",
		"Transhipments": "Transhipments",
		"Notification_date": "Credit Notification Date",
		"NOTIFICATION_DATE_TIPS": "Date by which the documentary credit must be notified to the seller (if different from Art. 5.3)",
		"DATE_PLACEHOLDER": "dd/mm/YYYY",
		"Days": "Days",
		"Percentage": "Percentage",
		"Place": "Place",
		"Other": "Other"
	},
	"A8": {
		"TITLE": "A-8 Documents",
		"ALERT_MSG": "Indicate here the documents that the Seller must procure. The parties are advised to check the Incoterm they have chosen in box A-3 of these Special Conditions. As regards transport documents, see also Introduction, § 8.",
		"TIP_MSG": "Clearly specify the documents required to facilitate customs clearance. Ensure that the documents are in line with the Incoterms® selected in Section A-3 of these Special Conditions. Verify the formal requirements for the documents in the destination country of the goods in collaboration with the buyer.",
		"Invoice": "Commercial invoice",
		"DDT": "Transport document",
		"Insurance_document": "Insurance document",
		"Origin_certificate": "Certificate of origin",
		"Preferential_origin_certificate": "Preferential certificate of origin",
		"Inspection_certificate": "Inspection certificate",
		"Packing_list": "Packing_list",
		"Other": "Other"
	},
	"A9": {
		"TITLE": "A-9 Termination Date",
		"ALERT_MSG": "TO BE COMPLETED ONLY IF THE PARTIES WISH TO MODIFY ARTICLE 10.3. If, for any reason (including force majeure), the products have not been delivered by the termination date the Buyer will have the right to IMMEDIATELY TERMINATE THE CONTRACT BY SIMPLE WRITTEN COMMUNICATION TO THE SELLER.",
		"TIP_MSG": "Defining a cancellation date offers protection to the buyer in case of excessive delay or force majeure. If the goods are not delivered by the set date, the buyer may declare the contract void.",
		"Termination_date": "Termination date",
		"DATE_PLACEHOLDER": "dd/mm/YYYY"
	},
	"A10": {
		"TITLE": "A-10 Liability for late delivery (Art. 10.1, 10.4, 11.3)",
		"ALERT_MSG": "TO BE COMPLETED ONLY IF THE PARTIES WISH TO MODIFY ARTICLES 10.1, 10.4 or 11.3.",
		"TIP_MSG": "Include any liquidated damages for delays, specifying the percentage and maximum acceptable delay duration. Defining penalties for delays can discourage the seller from causing delays. E.g., 0.5% per week up to a maximum of 5% of the price of the delayed goods.",
		"LATE_DELIVERY_PENALTY_CTA": "The penalty for late delivery will be the percentage specified below (of the price of the products delivered late) for each week of delay, within the maximum percentage limit specified below (of the price of the products above). ",
		"EXCEED_AMOUNT_CTA": "In the event of termination for late delivery, Seller's liability for damages due to late delivery is limited to the percentage specified below of the price of the undelivered products.",
		"SELLER_RESPONSABILITY_CTA": "The Seller's liability for damages resulting from non-conformity of the goods will be limited to proven damage (including consequential damage, loss of profit, etc.) up to the maximum limit of the percentage specified below of the contract price.",
		"Price_percentage": "Price percentage",
		"Max_limit_percentage": "Percentage of the maximum limit",
		"Seller_percentage": "Seller percentage"
	},
	"A11": {
		"TITLE": "A-11 Limitation of liability for non-compliance (Article 11.5)",
		"ALERT_MSG": "TO BE COMPLETED ONLY IF THE PARTIES WISH TO AMEND ART. 11.5",
		"TIP_MSG": "Define a maximum limit for damages the seller must pay in case of delivery delays. This can protect the seller from excessive compensation claims.",
		"PRICE_REDUCTION_PERCENTAGE_CTA": "The price reduction for accepting non-compliant products cannot exceed the percentage specified below of the price of such products.",
		"PRICE_REDUCTION_VALUE_CTA": "The price reduction for accepting non-compliant products cannot exceed the amount specified below.",
		"PRICE_REDUCTION_OTHER_CTA": "The price reduction for accepting non-conforming products will be managed as follows.",
		"Percentage": "Percentage",
		"Amount": "Amount",
		"Other": "Other"
	},
	"A12": {
		"TITLE": "A-12 Limitation of liability in case the buyer decides to keep non-compliant products (Art. 11.6)",
		"ALERT_MSG": "TO BE COMPLETED ONLY IF THE PARTIES WISH TO AMEND ART. 11.6",
		"TIP_MSG": "Define a maximum limit for damages the seller must pay in case of non-compliant goods. This can protect the seller from excessive compensation claims.",
		"EXCEED_PERCENTAGE_CTA": "The price reduction for accepting non-compliant products will not exceed the (specified) percentage of the price of such products.",
		"EXCEED_AMOUNT_CTA": "The price reduction for accepting non-compliant products will not exceed the (specified) amount",
		"Percentage": "Percentage",
		"Amount": "Amount"
	},
	"A13": {
		"TITLE": "A-13 Limitation period (Art. 11.8)",
		"ALERT_MSG": "TO BE COMPLETED ONLY IF THE PARTIES WISH TO AMEND ART. 11.8",
		"TIP_MSG": "Set a deadline by which the buyer must notify the seller of any defects or non-compliance of the goods. This protects the seller from late claims.",
		"DAYS_MSG": "Any action for non-conformity of the products (as defined in article 11.8) must be brought by the Seller within the days from the date of arrival of the products at destination specified below.",
		"Days": "Days"
	},
	"A14": {
		"TITLE": "A-14 Applicable law (Art. 1.2)",
		"ALERT_MSG": "To be completed only if the parties wish to subject the sales contract to a national law instead of the CVIM. The solution indicated below is not recommended (see Introduction, § 3).",
		"TIP_MSG": "The choice of applicable law can affect remedies in case of disputes; consider whether to apply the law of the seller’s or buyer’s country. If not otherwise specified, the law of the seller’s country will apply. The Vienna Convention (CISG) is often used to harmonize international sales contracts.",
		"LAW_MSG": "This sales contract is subject to the following internal law.",
		"LAW2_MSG": "Any matter not covered by the CVIM shall be governed by the following law.",
		"Law": "Law"
	},
	"A15": {
		"TITLE": "A-15 Dispute Resolution (Art. 14)",
		"ALERT_MSG": "The two solutions indicated below (arbitration or ordinary courts) are alternatives: the parties cannot choose both. In the absence of choice, ICC arbitration will apply, in accordance with Article 14.",
		"TIP_MSG": "Choose between arbitration or ordinary court for resolving disputes; arbitration can be faster and less costly in the long term. Arbitration, governed by the ICC International Court, can be more effective for international disputes. Specify the location and language of the arbitration to avoid ambiguity.",
		"Dispute_resolution": "Dispute Resolution",
		"OPTION_0": "ADR - Alternative Dispute Resolution",
		"OPTION_1": "ARBITRATION",
		"OPTION_2": "CCI (in accordance with art. 14.1)",
		"OPTION_3": "Other (specify)",
		"OPTION_4": "Ordinary jurisdiction (specify)",
		"Other": "Other",
		"Competent_court": "Competent court"
	},
	"A16": {
		"TITLE": "A-16 Other",
		"TIP_MSG": "Use this space to add any other information or clause that does not fit into the previous sections. Ensure that all additional clauses are clear and do not contradict other contract terms.",
		"Other": "Altro"
	},
	"accountPage": {
		"My_account": "My account",
		"Anonymous_identity": "Anonymous identity",
		"My_contracts": "My contracts",
		"Contract_model": "Contract model",
		"My_role": "My role",
		"Seller": "Seller",
		"Buyer": "Buyer",
		"Contract_name": "Contract name",
		"Create_contract": "Create contract"
	},
	"editAccountPage": {
		"Name": "Name",
		"Surname": "Surname",
		"Email": "Email",
		"Phone": "Phone",
		"PLACEHOLDER_name": "Your name",
		"PLACEHOLDER_surname": "Your surname",
		"PLACEHOLDER_email": "Your email",
		"PLACEHOLDER_phone": "Your phone"
	},
	"viewContractPage": {
		"View_contract": "View contract",
		"Contract_draft": "Contract draft",
		"CONTRACT_DRAFT_MSG": "This contract is a draft. Store it on the blockchain to historicize it in a persistent and secure manner via the blockchain system.",
		"Store_contract": "Store contract",
		"Contract_saved": "Contract saved",
		"CONTRACT_SAVED_MSG": "This contract has been historicized in a persistent and secure manner via the blockchain system.",
		"Sign_contract": "Sign contract",
		"Contract_signed": "Contract signed",
		"CONTRACT_SIGNED_MSG": "This contract has been historicized and signed in a persistent and secure manner via the blockchain system. The goods are ready to be shipped.",
		"Contract_status": "Stato del contratto",
		"Saved": "Saved",
		"Signed": "Signed",
		"Shipped": "Shipped",
		"Delivered": "Delivered",
		"Completed": "Completed",
		"Payment_status": "Payment status",
		"Contract_details": "Contract details",
		"Contract_id": "Contract ID",
		"Contract_name": "Contract name",
		"Contract_model": "Contract model",
		"Contract_value": "Contract value",
		"Seller": "Seller",
		"Buyer": "Buyer",
		"Seller_signature": "Seller signature",
		"Buyer_signature": "Buyer signature",
		"Contract_id_on_blockchain": "Contract ID on blockchain",
		"Verify": "Verify",
		"Change_log": "Change log",
		"Open_PDF": "Open PDF"
	},
	"viewContractChangesPage": {
		"Change_log": "Change log",
		"Last_changes" : "Last changes",
		"LAST_CHANGES_DESCRIPTION": "Here you can find all changes made on contract"
	},
	"shareContractPage": {
		"Share_your_contract": "Share your contract",
		"SHARE_MSG": "Write a message and share your contract",
		"SHARE_TEXTAREA_MSG": "Hi! Here the contract I initialized, click and add it to your TradeOnChain account and it is ready to be signed!",
		"Sign_contract": "Sign contract",
		"SHARE_CTA": "Share",
		"URL_MSG": "Contract shareable link",
		"URL_CTA": "Copy link",
		"QRCODE_MSG": "Contract shareable QR Code"
	},
	"addContractPage": {
		"Add_contract": "Add contract",
		"ADD_CONTRACT_MSG": "Click on the 'Add' button to add this contract to your account and collaborate on the drafting."
	},
	"languages": {
		"Italian": "Italian",
		"English": "English"
	},
	"countries": {
		"af": "Afghanistan",
		"al": "Albania",
		"dz": "Algeria",
		"ad": "Andorra",
		"ao": "Angola",
		"ar": "Argentina",
		"am": "Armenia",
		"au": "Australia",
		"at": "Austria",
		"az": "Azerbaijan",
		"bh": "Bahrain",
		"bd": "Bangladesh",
		"by": "Belarus",
		"be": "Belgium",
		"bz": "Belize",
		"bj": "Benin",
		"bt": "Bhutan",
		"bo": "Bolivia",
		"ba": "Bosnia and Herzegovina",
		"bw": "Botswana",
		"br": "Brazil",
		"bn": "Brunei",
		"bg": "Bulgaria",
		"bf": "Burkina Faso",
		"bi": "Burundi",
		"kh": "Cambodia",
		"cm": "Cameroon",
		"ca": "Canada",
		"cv": "Cape Verde",
		"td": "Chad",
		"cl": "Chile",
		"cn": "China",
		"co": "Colombia",
		"cg": "Congo",
		"cr": "Costa Rica",
		"hr": "Croatia",
		"cu": "Cuba",
		"cy": "Cyprus",
		"cz": "Czech Republic",
		"dk": "Denmark",
		"dj": "Djibouti",
		"do": "Dominican Republic",
		"ec": "Ecuador",
		"eg": "Egypt",
		"sv": "El Salvador",
		"ee": "Estonia",
		"et": "Ethiopia",
		"fi": "Finland",
		"fr": "France",
		"ga": "Gabon",
		"gm": "Gambia",
		"ge": "Georgia",
		"de": "Germany",
		"gh": "Ghana",
		"gr": "Greece",
		"gt": "Guatemala",
		"gn": "Guinea",
		"ht": "Haiti",
		"hn": "Honduras",
		"hu": "Hungary",
		"is": "Iceland",
		"in": "India",
		"id": "Indonesia",
		"ir": "Iran",
		"iq": "Iraq",
		"ie": "Ireland",
		"il": "Israel",
		"it": "Italy",
		"jm": "Jamaica",
		"jp": "Japan",
		"jo": "Jordan",
		"kz": "Kazakhstan",
		"ke": "Kenya",
		"kr": "South Korea",
		"kw": "Kuwait",
		"lv": "Latvia",
		"lb": "Lebanon",
		"lr": "Liberia",
		"ly": "Libya",
		"lt": "Lithuania",
		"lu": "Luxembourg",
		"mg": "Madagascar",
		"my": "Malaysia",
		"ml": "Mali",
		"mt": "Malta",
		"mx": "Mexico",
		"mn": "Mongolia",
		"me": "Montenegro",
		"ma": "Morocco",
		"mz": "Mozambique",
		"mm": "Myanmar",
		"np": "Nepal",
		"nl": "Netherlands",
		"nz": "New Zealand",
		"ng": "Nigeria",
		"no": "Norway",
		"om": "Oman",
		"pk": "Pakistan",
		"pa": "Panama",
		"py": "Paraguay",
		"pe": "Peru",
		"ph": "Philippines",
		"pl": "Poland",
		"pt": "Portugal",
		"qa": "Qatar",
		"ro": "Romania",
		"ru": "Russia",
		"rw": "Rwanda",
		"sa": "Saudi Arabia",
		"sn": "Senegal",
		"rs": "Serbia",
		"sg": "Singapore",
		"sk": "Slovakia",
		"si": "Slovenia",
		"za": "South Africa",
		"es": "Spain",
		"lk": "Sri Lanka",
		"se": "Sweden",
		"ch": "Switzerland",
		"sy": "Syria",
		"tw": "Taiwan",
		"th": "Thailand",
		"tn": "Tunisia",
		"tr": "Turkey",
		"ua": "Ukraine",
		"ae": "United Arab Emirates",
		"gb": "United Kingdom",
		"us": "United States",
		"uy": "Uruguay",
		"ve": "Venezuela",
		"vn": "Vietnam",
		"zm": "Zambia",
		"zw": "Zimbabwe"
	}
}